<template>
   <el-dialog
    title="关联订单"
    :modelValue="props.modelValue"
    width="400"
    @open="openModal"
    :before-close="closeModal"
    class="model-open-dialog"
    :close-on-click-modal="false"
    :close-on-press-escape="false">
    <div class="content">
     <div class="form">
      <div class="text">订单号：  </div>
       <el-input class="w280"  placeholder="请输入订单号"  maxlength="40" v-model.trim="formData.orderSn"></el-input>
       <el-popover
                placement="top-start"
                title="提示"
                trigger="hover"
                :content="text"

              >
                <template #reference>
                  <el-icon  style="margin-left: 10px;color: #AAAAA2;"><QuestionFilled /></el-icon>
                </template>
              </el-popover>
      </div>

    </div>
    <template #footer>
      <el-button @click="closeModal">取消</el-button>
      <el-button type="primary" @click="getOrderDetail">确定</el-button>
    </template>
  </el-dialog>
</template>

<script setup>
import message from "@/utils/tool/message";
import { reactive ,toRefs ,ref ,inject,computed } from "vue";
import {getConfig} from '../../uitl';
import { orderCodeDetail } from "@/utils/api/order";
import SelectByModalNew from "@components/form/selectByModalNew/index.vue";
const inputRef = ref(null)
const baeeForom = reactive({
  orderSn: '',
  thirdId: '',
  thirdName: ''
})
//获取配置文件
const formData = reactive({
  ...baeeForom
})
const orderInfo = inject('orderInfo')
const props = defineProps({
  modelValue:{
    type:Boolean,
    default:false
  },
  orderType:{
    type:[String,Number],
    default:''
  }
})
const { orderType } = toRefs(props)
const emit = defineEmits(['update:modelValue','confirm'])
const closeModal = ()=>{
  Object.assign(formData,baeeForom)
  emit('update:modelValue',false)
}
const text = computed(()=>{
  return `请输入非本企业的${orderType.value == 1 ? '销售订单' : '采购订单'}号，以便快速创建关联订单。`
})

const handleConfirm = ()=>{
  orderInfo.value = {...orderInfo.value,...formData}
  emit('confirm',orderInfo.value)
  closeModal()
}
const openModal = ()=>{
}
const getOrderDetail = async () => {
  const { orderSn } = formData
  if(!orderSn){
    message.error('请输入订单号')
    return
  }
  const {data,code,msg} = await orderCodeDetail({orderSn,orderType:orderType.value})
  if(code !== 0){
    message.error(msg)
    return
  }
  const companyName = data?.census?.companyName || ''
  formData.thirdName = companyName || ''
  orderInfo.value = data
  handleConfirm()
}
</script>

<style lang="scss" scoped>
.model-open-dialog{
  .form{
    display:flex;
    align-items: center;
    flex-shrink: 0;
    .text{
      width: 70px;
    }
  }
}
</style>