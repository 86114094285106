<template>
  <!-- 产品详情弹窗 -->
  <div>
    <el-dialog
        v-bind="$attrs"
        :title="orderText"
        width="1400"
        class="goodInfo"
        :close-on-click-modal="false"
        destroy-on-close
        align-center
        @open="openDetail"
        @close="close"
    >
      <div class="zh_content">
        <div class="info">
          <div class="dp_f">
            <div class="zh_title">基本信息</div>
            <div class="btn-box">
              <QrCodeBtn v-if="orderInfo.qrCode" :qr-code="orderInfo.qrCode"></QrCodeBtn>
              <el-button type="primary" @click="printHmtl" v-if="showPrint()">打印</el-button>
            </div>
          </div>
          <div class="zh_introduce status3">
            <div class="item">
              <div class="text">订单编号：</div>
              <div class="desc">{{ orderInfo.orderSn }}</div>
            </div>
            <div class="item" v-if="orderInfo.thirdName">
              <div class="text">{{ orderInfoText.thirdName }}：</div>
              <div class="desc">{{ orderInfo.thirdName }}</div>
            </div>
            <div class="item">
              <div class="text">{{ orderInfoText.stockName }}：</div>
              <div class="desc">{{ orderInfo.stockName }}</div>
            </div>
            <div class="item">
              <div class="text">{{ orderInfoText.userName }}：</div>
              <div class="desc">{{ orderInfo.stockUserName }}</div>
            </div>
            <div class="item">
              <div class="text">{{ orderInfoText.outTime }}：</div>
              <div class="desc">{{ orderInfo.stockTime }}</div>
            </div>
          </div>
          <div class="zh_title mt32">产品信息</div>
          <div class="zh_introduce">
            <div class="item">
              <div class="text">产品数量：</div>
              <div class="desc">{{ goodsNum.count || 0 }}</div>
            </div>
            <div class="item">
              <div class="text">合计金额(元)：</div>
              <div class="desc">{{ goodsNum.totalPrice || 0 }}</div>
            </div>
          </div>
          <div class="tabList">
            <el-table :data="tableData" class="zh-no-table-border"
                      :row-class-name="tableRowClassNameNew"
                      :cell-style="{border:'none'}"
                      :span-method="objectSpanMethod([1,2,3,4])"
                      :header-row-style="{border:'none'}" style="width: 100%" height="96%">
              <el-table-column
                  type="index"
                  width="100"
                  label="序号"
                  align="center"
                  fixed
              >
              </el-table-column>
              <el-table-column align="center" class-name="table_text2" show-overflow-tooltip>
                <template #header>
                  <div>产品名称</div>
                </template>
                <template #default="scope">
                  {{ scope.row.productName }}
                </template>
              </el-table-column>
              <el-table-column width="100" align="center" label="简称" class-name="table_text2" show-overflow-tooltip>
                <template #default="scope">
                  {{ scope.row.subName || '--' }}
                </template>
              </el-table-column>
              <el-table-column width="130" align="center" label="产品品牌" class-name="table_text"
                               show-overflow-tooltip>
                <template #default="scope">
                  {{ scope.row.brandName || '--' }}
                </template>
              </el-table-column>
              <el-table-column align="center" label="规格/型号" class-name="table_text2"
                               show-overflow-tooltip>
                <template #default="scope">
                  {{ scope.row.modelName || '--' }}
                </template>
              </el-table-column>
              <el-table-column width="150" align="center" label="产品单位" class-name="table_text2"
                               show-overflow-tooltip>
                <template #default="scope">
                  <div v-html=" getUnitName(scope.row)"></div>
                </template>
              </el-table-column>
              <el-table-column width="100" align="center">
                <template #header>
                  <div>单价(元)</div>
                </template>
                <template #default="scope">
                  <div class="table_text2">
                    {{ scope.row.price }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="210" align="center">
                <template #header>
                  <div>计划数量/计划金额(元)</div>
                </template>
                <template #default="scope">
                  <div class="table_text2">
                    <span class="table_text2"> {{ scope.row.count || 0 }}</span> <span class="unit">/</span><span
                      class="table_text2 ml_4" v-if="scope.row.totalPrice">
                   {{ (Number(scope.row.count || 0) * Number(scope.row.price || 0)).toFixed(2) }}
                 </span>
                    <span class="table_text2" v-else>{{ 0 }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="200" align="center" :label="inColumnsTableConfig.salesText">
                <template #default="scope">
                  <div class="table_dp_item">
                    <span class="table_text2">{{ scope.row.realityCount || '--' }}</span> <span
                      class="unit">/</span><span
                      class="table_text2"> {{ scope.row.realityTotalPrice || '--' }}</span><span class="contBtn"
                                                                                               @click="showRfid(scope.row)"
                                                                                                 v-if="scope.row.labelCount > 0 && !noMore && !scope.row.scattered">查看明细</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="remark">
            <div class="title">备注</div>
            <div class="remark_text">
              {{ orderInfo.remark }}
            </div>
            <div class="btnBox">
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <RfidList v-model="rfidShow" v-model:goodsInfo="goodsInfo"></RfidList>
  </div>
</template>
<script setup>
import {objectSpanMethod, tableRowClassNameNew} from '@/utils/tool.js';
import {computed, getCurrentInstance, ref, toRefs} from "vue";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import {getConfig} from '../uitl'
import useRfid from "@utils/tool/product/useRfid";
import useOrder from "@utils/tool/order/useOrder";
import {getUnitName} from "@utils/tool/orderTools";
import QrCodeBtn from './qrCodeBtn.vue'

const props = defineProps({
  orderNum: {
    type: String,
    default: '',
  },
  noMore: {
    type: Boolean,
    default: false,
  },
});
const { orderNum ,noMore} = toRefs(props);
const { proxy } = getCurrentInstance();
const emit = defineEmits(['close'])
const addShow = ref(false)
const route = useRoute();
const store = useStore();
const orderType = ref('');
//获取配置文件
const orderInfoText = ref({})
const inColumnsTableConfig = ref({})

const {goodsInfo, showRfidFunc} = useRfid()
const {orderInfo, setOrderInfo, tableData, getGoodsListDetFunc, getInStockGoodsList} = useOrder()
//打开标签明细
const showRfid = (e) => {
  showRfidFunc(e, () => {
    goodsInfo.orderSn = orderInfo.orderSn;
    goodsInfo.orderType = orderInfo.orderType;
    rfidShow.value = true;
  })
};
const goodsNum = computed(() => {
  let count = 0;
  let totalPrice = 0
  tableData.value.forEach((item) => {
    count += Number(item.realityCount) || 0;
    totalPrice +=  Number(item.realityTotalPrice) || 0
  });
  return{
    count : Number.isInteger(count) ? count : count.toFixed(2),
    totalPrice:totalPrice.toFixed(2)
  }
});
const rfidShow = ref(false);
const orderText = ref('')
const openDetail = () => {
  setOrderInfo()
  orderType.value = orderInfo.orderType || route.params.orderType || orderNum.value || 0
  orderInfoText.value = getConfig( orderType.value ).orderInfoText.value
  orderText.value = getConfig( orderType.value ).orderText.value
  inColumnsTableConfig.value = getConfig( orderType.value ).inColumnsTableConfig.value
  getGoodsListDet()
  getGoodsList()
};
const getGoodsListDet = ()=>{
  getGoodsListDetFunc(orderType.value, (data) => {
    if (data.time) {
      data.time = proxy.$filters.timeFormat(data.time, 1)
    }
    if (data.stockTime) {
      data.stockTime = proxy.$filters.timeFormat(data.stockTime, 1)
    }
  })
}
//获取产品列表
const getGoodsList = ()=>{
  getInStockGoodsList(orderType.value, () => {
    if (orderInfo.orderStatus === 2) {
      tableData.value.push({})
    }
  })
}

const close = ()=>{
  proxy.$emit('close')
  proxy.$store.commit("PURCHASE_ORDER_INFO", {});
  tableData.value = []
}
const closeAdd = ()=>{
  addShow.value =false
  getGoodsList()
}

const showPrint = () => {
  let list = ["1", "3", "7", "10"]
  if (list.includes(orderType.value)) {
    return true
  }
  return true
}


//跳转打印页面
const printHmtl = () => {
  let url = `/print?orderSn=${orderInfo.orderSn}&orderType=${orderType.value}`
  window.open(url)
}

</script>

<script>
import RfidList from "@/components/rfidList/indexByOrder.vue";
import { ArrowDown } from "@element-plus/icons-vue";
import SelectGoodsFromList from "@/components/selectGoodsFromList/index.vue";
import SelectByAbcModal from "@/components/selectByAbcModal/index.vue";
export default {
  components: {
    ArrowDown,
    RfidList,
    SelectByAbcModal,
    SelectGoodsFromList
  },
};
</script>
<style lang="scss" scoped>
.goodInfo {
  .zh_content {
    padding: 16px;
    height: 824px;
    box-sizing: border-box;
    position: relative;
    .zh_introduce{
      flex-wrap: wrap;
      .item{
        width: 33%;
      }
    }
    .tabList {
      height:398px;
      overflow-y: auto;
      @include overFlow();
      padding-top: 20px;
      padding-bottom: 16px;
      box-sizing: border-box;
      .name {
        color: #73737f;
      }
      .text2 {
        color: $fsColor;
      }
      .contBtn {
        font-size: 12px;
        color: $fsColor3;
        display: inline-block;
        line-height: 1;
        cursor: pointer;
        margin-left: 8px;
        position: relative;
        top: -1px;
      }
      .el-input__inner {
        background-color: #f9fbfb !important;
      }
    }
    .remark {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 178px;
      padding: 0 32px 12px;
      box-sizing: border-box;
      .title {
        line-height: 1;
        padding-left: 6px;
        border-left: 3px solid $fsColor3;
        font-size: 16px;
        color: $fsColor;
      }
      .remark_text{
        text-indent: 2em;
        color: #737480;
        font-size: 14px;
        line-height: 2;
        margin-top: 10px;
      }
      .input {
        margin-top: 16px;
      }
      .btnBox {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        right: 32px;
        bottom: 12px;
        .info_btn{
          width: 112px;
        }
        .select {
          margin: 0 8px;
          width: 160px;
          height: 40px;
         :deep(.el-input){
            height: 40px;
            line-height: 40px;
          }
        }
      }
    }
  }
}

:deep(.el-textarea__inner) {
  height: 76px;
  border-radius: 8px;
  background-color: #f9fbfb;
}
</style>
