<template>
  <!-- 产品详情弹窗 -->
  <el-dialog
    v-bind="$attrs"
    :title="orderTitleText"
    width="1400"
    class="goodInfo"
    :close-on-click-modal="false"
    destroy-on-close
    align-center
    @open="openDetail"
    @close="close"
  >
    <div class="zh_content">
      <div class="info">
        <div class="dp_f">
          <div class="zh_title">基本信息</div>
          <div class="btn-box">
            <QrCodeBtn v-if="orderInfo.qrCode" :qr-code="orderInfo.qrCode"></QrCodeBtn>
            <el-button type="primary" @click="printHmtl" v-if="showPrint()">打印</el-button>
          </div>
        </div>
        <div class="zh_introduce status3">
          <div class="item">
            <div class="text">订单编号：</div>
            <div class="desc">{{ orderInfo.orderSn }}</div>
          </div>
          <div class="item">
            <div class="text">{{ orderInfoText.thirdName }}：</div>
            <div class="desc">{{ orderInfo.thirdName }}</div>
          </div>
          <div class="item">
            <div class="text">{{ orderInfoText.stockName }}：</div>
            <div class="desc">{{ orderInfo.stockName }}</div>
          </div>
          <div class="item">
            <div class="text">{{ orderInfoText.userName }}：</div>
            <div class="desc">{{ orderInfo.userName }}</div>
          </div>
          <div class="item">
            <div class="text">{{ orderInfoText.time }}：</div>
            <div class="desc">{{ orderInfo.time }}</div>
          </div>
        </div>

        <div class="zh_title mt32">产品信息</div>
        <div class="zh_introduce">
          <div class="item">
            <div class="text">产品数量：</div>
            <div class="desc">{{ goodsNum.count }}</div>
          </div>
          <div class="item">
            <div class="text">产品金额(元)：</div>
            <div class="desc">￥{{ goodsNum.totalPrice }}</div>
          </div>
        </div>
        <div class="tabList">
          <el-table :data="tableData" class="zh-no-table-border"
                    :row-class-name="tableRowClassNameNew"
                    :cell-style="{border:'none'}"
                    :header-row-style="{border:'none'}" style="width: 100%" height="96%"
                    :span-method="objectSpanMethod([1,2,3,4])">
            <el-table-column
                type="index"
                width="100"
                label="序号"
                align="center"
                fixed
            />
            <el-table-column
                width="254"
                align="center"
                class-name="table_text2"
                show-overflow-tooltip
            >
              <template #header>
                <div>产品名称</div>
              </template>
              <template #default="scope">
                {{ scope.row.productName }}
              </template>
            </el-table-column>
            <el-table-column
                width="100"
                align="center"
                label="简称"
                class-name="table_text2"
                show-overflow-tooltip
            >
              <template #default="scope">
                  {{ scope.row.subName || "--" }}
              </template>
            </el-table-column>
            <el-table-column
                width="130"
                align="center"
                class-name="table_text"
                label="产品品牌"
                show-overflow-tooltip
            >
              <template #default="scope">
                {{ scope.row.brandName || "--" }}
              </template>
            </el-table-column>
            <el-table-column
                width="150"
                align="center"
                class-name="table_text2"
                label="规格/型号"
                show-overflow-tooltip
            >
              <template #default="scope">
                {{ scope.row.modelName || "--" }}
              </template>
            </el-table-column>
            <el-table-column
                width="150"
                align="center"
                class-name="table_text2"
                show-overflow-tooltip
                label="产品单位"
            >
              <template #default="scope">
                <div
                    v-html="getUnitName(scope.row)"></div>
              </template>
            </el-table-column>
            <el-table-column width="150" align="center">
              <template #header>
                <div>计划数量</div>
              </template>
              <template #default="scope">
                <div class="table_text2">
                  {{ scope.row.count }}
                </div>
              </template>
            </el-table-column>
            <el-table-column width="100" align="center">
              <template #header>
                <div>单价(元)</div>
              </template>
              <template #default="scope">
                <div class="table_text2">
                  {{ scope.row.price }}
                </div>
              </template>
            </el-table-column>
            <el-table-column width="200" align="center">
              <template #header>
                <div>小计(元)</div>
              </template>
              <template #default="scope">
                <div class="table_text2">
                  {{ scope.row.totalPrice }}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="remark">
          <div class="title">备注</div>
          <div class="remark_text">
            {{ orderInfo.remark }}
          </div>
          <div class="btnBox">
            <el-button class="btn1 zh-btn info_btn" @click="delOrder"
                       v-if='!orderNum'
              >删除</el-button
            >
            <el-button
                v-if='!orderNum'
                class="btn1 info_btn"
                type="primary"
                @click="clickChange"
              >编辑</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
  <ChangeOrder v-model="changeShow" v-if='!orderNum' @close="openDetail" @closeAll="closeAll"></ChangeOrder>
</template>
<script setup>
import {useStore} from "vuex";
import {ElMessageBox} from "element-plus";
import {useRoute} from "vue-router";
import {orderDel, orderGoodsList} from "@/utils/api/order.js";
import {getConfig} from "../uitl";
import ChangeOrder from "./changeOrder.vue";
import {objectSpanMethod, resetGoodsList, tableRowClassNameNew} from "@/utils/tool.js";
import {computed, getCurrentInstance, reactive, ref, toRefs, watch,} from "vue";
import {orderBelong} from "@views/order/orderConfig";
import {useViewPort} from "@utils/tool/useVIewPort";
import {getUnitName} from "@utils/tool/orderTools";
import QrCodeBtn from './qrCodeBtn.vue'

const {height} = useViewPort()
const props = defineProps({
  orderNum: {
    type: String,
    default: '',
  },
});
const { orderNum } = toRefs(props);

const { proxy } = getCurrentInstance();
const route = useRoute();
const emit = defineEmits(["close"]);
const store = useStore();
const changeShow = ref(false);
const addShow = ref(false);
const orderType = ref("1")
const orderTitleText = ref('')
//获取配置文件
const orderInfoText = ref({})
//编辑
const clickChange = () => {
  changeShow.value = true;
};
//删除订单
const delOrder = () => {
  ElMessageBox.confirm("确认删除该订单！", "提示", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    customClass: "zh-message-box",
  }).then(() => {
    orderDel({
      orderSn: orderInfo.orderSn,
      orderType: orderInfo.orderType,
    }).then((res) => {
      if (res.code === 6999) {
        proxy.$message.error(res.msg);
        return
      }
      if (res.code !== 0 && res.code !== 6999) {
        ElMessageBox.confirm(res.msg, "提示", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          customClass: "zh-message-box",
          showCancelButton:false,
        }).then(() => {
          emit("close");
        });
        return;
      }
      proxy.$message.success("删除成功");
      emit("close");
    });
  });
};
const orderInfo = reactive({
  orderSn: "",
  supplierName: "",
  productName: [],
  createTime: "",
  createUserName: "",
  remark: "",
  orderStatus: 0,
});
const goodsInfo = reactive({
  orderSn: "",
  productId: "",
  modelId: "",
  productName: "",
  encode: [],
  unitName: "",
  modelName: "",
  checkPrice: "",
  brandName: "",
  checkCount: "",
  subName: "",
  price: "",
  img: "",
});

const goodsNum = computed(() => {
  let count = 0;
  let totalPrice = 0;
  tableData.value.forEach((item) => {
    count += Number(item.count) || 0;
    totalPrice += Number(item.totalPrice) || 0;
  });
  return {
    count : Number.isInteger(count) ? count : count.toFixed(2),
    totalPrice: totalPrice.toFixed(2),
  };
});
const tableData = ref([]);
const orderText = ref('')
const openDetail = () => {
  reactiveAssign(orderInfo, store.state.purchaseOrderInfo);
  orderType.value = orderInfo.orderType || route.params.orderType || orderNum.value || 0;
  orderInfoText.value = getConfig(orderType.value).orderInfoText.value
  orderText.value = getConfig(orderType.value).orderText.value
  orderTitleText.value = orderBelong[orderType.value]
  getGoodsList();
  if (changeShow.value) {
    changeShow.value = false;
  }
};
//获取产品列表
const getGoodsList = () => {
  orderGoodsList({
    orderSn: orderInfo.orderSn,
    orderType: orderInfo.orderType,
    orderStatus: 0,
  }).then((res) => {
    if (res.code !== 0) {
      proxy.$message.error(res.msg);
      return;
    }
    let list = res.data?.list || []
    tableData.value = resetGoodsList(list, (item) => {
      item.price = proxy.$filters.toYuan(item.price, "元");
      item.totalPrice = proxy.$filters.toYuan(item.totalPrice, "元");
      item.orderType = orderType.value || ''
    });
  });
};
const delItem = (index) => {
  tableData.value.splice(index, 1);
};
//确认是否可提交
const canConfirm = computed(() => {
  return (
    tableData.value.every((item, index) => {
      //判断item.count非空不等于0
      if (index === tableData.value.length - 1) return true;
      return item.productId && item.price && item.price != 0;
    }) && tableData.value.length > 1
  );
});
const close = () => {
  proxy.$emit("close");
  proxy.$store.commit("PURCHASE_ORDER_INFO", {});
  tableData.value = [];
};
const closeAdd = () => {
  addShow.value = false;
  getGoodsList();
};
const closeAll = ()=>{
  changeShow.value = false
  proxy.$emit("close");
  tableData.value = [];
}
const showPrint = () => {
  let list = ["1", "3", "7", "10"]
  return list.includes(orderType.value + '') && !orderNum.value;
}
const printHmtl = () => {
  let url = `/print?orderSn=${orderInfo.orderSn}&orderType=${orderType.value}`
  window.open(url)
}
</script>

<script>
import RfidList from "@/components/rfidList/index.vue";
import AddRfidList from "@/components/rfidList/addRfidList.vue";
import { reactive, toRefs, ref, watch, getCurrentInstance } from "vue";
import { reactiveAssign } from "@/utils/tool.js";
import { ArrowDown } from "@element-plus/icons-vue";
import SelectGoodsFromList from "@/components/selectGoodsFromList/index.vue";
import SelectByAbcModal from "@/components/selectByAbcModal/index.vue";
export default {
  name: "oredrInfo",
  components: {
    ArrowDown,
    RfidList,
    AddRfidList,
    SelectByAbcModal,
    SelectGoodsFromList,
  },
};
</script>
<style lang="scss" scoped>
.goodInfo {
  .qrCode{
          width: 60px;
          height: 60px;
        }
  .zh_content {
    padding:0 16px 0;
    height: 824px;
    box-sizing: border-box;
    position: relative;
    .zh_introduce {

      &.status3 {
        flex-wrap: wrap;
        height: 80px;
        padding: 15px;
        box-sizing: border-box;
        .item {
          width: 33%;
          @include textOverflow(33%);
          margin-bottom: 15px;
        }
      }
    }
    .tabList {
      height: 398px;
      overflow-y: auto;
      @include overFlow();
      padding-top: 20px;
      padding-bottom: 16px;
      box-sizing: border-box;
      .name {
        color: #73737f;
      }
      .text2 {
        color: $fsColor;
      }
      .contBtn {
        font-size: 12px;
        color: $fsColor3;
        display: inline-block;
        cursor: pointer;
        &.top1 {
          margin-left: 6px;
          position: relative;
          top: -1px;
        }
      }
      .el-input__inner {
        background-color: #f9fbfb !important;
      }
    }
    .remark {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 178px;
      padding: 0 32px 12px;
      box-sizing: border-box;
      .title {
        line-height: 1;
        padding-left: 6px;
        border-left: 3px solid $fsColor3;
        font-size: 16px;
        color: $fsColor;
      }
      .remark_text {
        text-indent: 2em;
        color: #737480;
        font-size: 14px;
        line-height: 2;
        margin-top: 10px;
      }
      .input {
        margin-top: 16px;
      }
      .btnBox {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        right: 32px;
        bottom: 12px;
        .info_btn {
          width: 112px;
        }
        .select {
          margin: 0 8px;
          width: 160px;
          height: 40px;
          :deep(.el-input) {
            height: 40px;
            line-height: 40px;
          }
        }
      }
    }
  }
}

:deep( .el-textarea__inner) {
  height: 76px;
  border-radius: 8px;
  background-color: #f9fbfb;
}
</style>
