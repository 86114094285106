<template>
  <div class="proOrder" v-if="addShow">
    <div class="content">
      <el-tabs v-model="tabChecked" class="demo-tabs" @tab-click="tabCheck">
        <el-tab-pane :label="tabText + '（' +countInfo.incomplete +'）'" name="warehous">
          <Complete v-if='tabChecked === "warehous"' @change="change" @add="handleAdd"/>
        </el-tab-pane>
        <el-tab-pane :label="tabTextIn +'（' +countInfo.complete +'）'" name="warehoused">
          <Incomplete v-if="tabChecked === 'warehoused'" @change="change"/>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
  <AddOrder v-else @close="closeAdd"></AddOrder>
</template>

<script>
import {getCurrentInstance, onMounted, reactive, ref, toRefs} from 'vue'
import Complete from './components/complete.vue';
import Incomplete from './components/incomplete.vue';
import AddOrder from "./addOrder/index.vue";
import {useRoute} from "vue-router";
import {orderConfig} from './orderConfig';
import {orderStatusCensus} from '@/utils/api/order.js';

export default {
  name:"orderList",
  components:{
    Complete,
    Incomplete,
    AddOrder
  },
  setup () {
    const { proxy} = getCurrentInstance();
    const route = useRoute();
    const addShow = ref(true)
    const state = reactive({
      count: 0,
      tabChecked:"warehous",
      countInfo:{
        incomplete:0,
        complete:0,
      },
      tabText:'',
      tabTextIn:'',
    })
    const change = ()=>{
      getCount();
    }
    const tabCheck = function(tab,e){
      proxy.$store.commit("PURCHASE_ORDER_INFO", {});
    }
    const handleAdd = function (){
      addShow.value = false
    }
    const closeAdd = ()=>{
      addShow.value = true
    }
    const orderType = route.params.orderType || 0;

    //获取数量统计
    const getCount = async () => {
      let res = await orderStatusCensus({
        orderType
      });
      if (res.code !== 0) {
        proxy.$message.error(res.msg);
        return;
      }
      state.countInfo.incomplete = res.data.incomplete;
      state.countInfo.complete = res.data.complete;
    };
    //获取配置文件
    function getConfig(){
      let obj = orderConfig[orderType]
      if(obj){
        state.tabText = obj.tabText
        state.tabTextIn = obj.tabTextIn
      }
    }
    onMounted(()=>{
      getCount();
      getConfig();
    })
    return {
      ...toRefs(state),
      tabCheck,
      change,
      addShow,
      handleAdd,
      closeAdd
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
.proOrder{
  height: 100%;
  padding:16px;
  box-sizing: border-box;
  position: relative;
  .el-tabs__header{
    margin-bottom: 0;
  }
  >.content{
    border-radius: 16px;
    background: #fff;
    padding: 12px 32px;
    height: 100%;
    box-sizing: border-box;
    position: relative;
  }
}

:deep(.el-tabs__content) {
  height:96%;
}

:deep(.el-tabs) {
  height: 100%;
}
</style>
