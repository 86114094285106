<template>
  <!-- 产品详情弹窗 -->
  <el-dialog
    v-bind="$attrs"
    title="编辑订单"
    width="1400"
    class="goodInfo"
    :close-on-click-modal="false"
    destroy-on-close
    align-center
    @open="openDetail"
  >
    <div class="zh_content">
      <div class="info">
        <div class="zh_title">基本信息</div>
        <div class="zh_introduce status3">
          <div class="item">
            <div class="text">{{ orderInfoText.orderSn }}：</div>
            <div class="desc">{{ orderInfo.orderSn }}</div>
          </div>
          <div class="item">
            <div class="text">{{ orderInfoText.stockName }}：</div>
            <div class="desc">{{ orderInfo.stockName }}</div>
          </div>
          <div class="item">
            <div class="text">{{ orderInfoText.thirdName }}：</div>
            <div class="desc">{{ orderInfo.thirdName }}</div>
          </div>
          <div class="item">
            <div class="text">{{ orderInfoText.userName }}：</div>
            <div class="desc">{{ orderInfo.userName }}</div>
          </div>
          <div class="item">
            <div class="text">{{ orderInfoText.time }}：</div>
            <div class="desc">{{ orderInfo.time }}</div>
          </div>
        </div>
        <div class="mt32 dp_f">
          <div class="zh_title">产品信息</div>
          <el-button type="primary" @click="openAdd">批量添加</el-button>
        </div>

        <div class="zh_introduce">
          <div class="item">
            <div class="text">产品数量：</div>
            <div class="desc">{{ goodsNum.count }}</div>
          </div>
          <div class="item">
            <div class="text">产品金额(元)：</div>
            <div class="desc">￥{{ goodsNum.totalPrice }}</div>
          </div>
        </div>
        <div class="tabList">
          <el-table :data="tableData" style="width: 100%" height="100%">
            <el-table-column
              type="index"
              width="100"
              label="序号"
              align="center"
              fixed
            />
            <el-table-column width="254" align="center">
              <template #header>
                <div>产品名称</div>
              </template>
              <template #default="scope">
                <div
                  v-if="scope.row.productName"
                  class="table_text2 tableName160"
                >
                  {{ scope.row.productName }}
                </div>
                <SelectGoodsFromList
                  v-model:goods="tableData[scope.$index]"
                  :propList="tableData"
                  @change="pushGoods"
                  :thirdId="orderInfo.thirdId"
                  :stockId="orderInfo.stockId"
                  v-else
                ></SelectGoodsFromList>
              </template>
            </el-table-column>
            <el-table-column
              width="100"
              align="center"
              class-name="table_text2"
              label="简称"
              show-overflow-tooltip
            >
              <template #default="scope">
                {{ scope.row.subName || "" }}
              </template>
            </el-table-column>
            <el-table-column
              width="130"
              align="center"
              label="产品品牌"
              show-overflow-tooltip
              class-name="table_text"
            >
              <template #default="scope">
                <template v-if="scope.row.productId">
                    {{ scope.row.brandName || "--" }}
                </template>
              </template>
            </el-table-column>
            <el-table-column
              width="100"
              align="center"
              label="产品单位"
              show-overflow-tooltip
            >
              <template #default="scope">
                <div
                    class="table_text2"
                    v-html="getUnitName(scope.row)"></div>
              </template>
            </el-table-column>
            <el-table-column
              width="100"
              align="center"
              label="规格/型号"
              show-overflow-tooltip
              class-name="table_text2"
            >
              <template #default="scope">
                {{ scope.row.modelName || "" }}
              </template>
            </el-table-column>
            <el-table-column width="140" align="center">
              <template #header>
                <div>计划数量</div>
              </template>
              <template #default="scope">
                <el-input
                  class="name tablInput w96"
                  placeholder="输入数量"
                  maxlength="5"
                  v-model="scope.row.count"
                  v-number
                  v-elInput
                  v-if="scope.row.productId"
                  @blur="
                    ($event) => {
                      setNum($event, scope.$index);
                    }
                  "
                />
              </template>
            </el-table-column>
            <el-table-column width="140" align="center">
              <template #header>
                <div>单价(元)</div>
              </template>
              <template #default="scope">
                <el-input
                  class="name tablInput w96"
                  placeholder="输入单价"
                  maxlength="10"
                  v-model="scope.row.price"
                  v-number.fixed
                  v-elInput
                  v-if="scope.row.productId"
                  @blur="
                    ($event) => {
                      setPrice($event, scope.$index);
                    }
                  "
                />
              </template>
            </el-table-column>
            <el-table-column width="120" align="center" label="小计(元)">
              <template #default="scope">
                <el-input
                  v-number.fixed
                  class="name tablInput w96"
                  v-model="scope.row.totalPrice"
                  placeholder="小计"
                  maxlength="10"
                  v-elInput
                  v-if="scope.row.productId"
                  @blur="
                    ($event) => {
                      setTotalPrice($event, scope.$index);
                    }
                  "
                />
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" fixed="right">
              <template #default="scope">
                <span
                  class="contBtn mr_10"
                  v-if="scope.$index !== tableData.length - 1"
                  @click="delItem(scope.$index)"
                  >移除</span
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="remark">
          <div class="title">备注</div>
          <div class="input">
            <el-input
              v-model="orderInfo.remark"
              type="textarea"
              placeholder="输入信息"
              class="textareaInput"
              input-style="border: none;box-shadow:none"
              resize="none"
              v-elInput
              maxlength="100"
            />
          </div>
          <div class="btnBox">
            <el-button class="btn1 info_btn zh-btn" @click="close"
              >取消</el-button
            >
            <el-button
              type="primary"
              class="btn1 info_btn"
              @click="handleEdit"
              :disabled="!canConfirm"
              >保存</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
  <Bind
    v-model="bindDia"
    title="批量添加"
    :propList="tableData"
    :stockId="orderInfo.stockId"
    :thirdId="orderInfo.thirdId"
    @close="closeAdd"
    @save="moreAddOk"
  ></Bind>
</template>
<script setup>
import Bind from "../addOrder/component/bind.vue";
import {orderAdd, orderGoodsList} from "@/utils/api/order.js";
import {ElMessageBox} from "element-plus";
import {computed, getCurrentInstance, reactive, ref, toRefs, watch,} from "vue";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import {cloneDeep} from "lodash";
import {getConfig} from "../uitl";
import {resetGoodsList} from "@utils/tool";
import {getUnitName} from "@utils/tool/orderTools";

const { proxy } = getCurrentInstance();
const store = useStore();
const bindDia = ref(false);
const route = useRoute();
const orderType = ref("");
//获取配置文件
const orderInfoText = ref({});
const closeAdd = () => {
  bindDia.value = false;
};
//打开批量添加弹窗
const openAdd = () => {
  bindDia.value = true;
};
//保存批量添加
const moreAddOk = (e) => {
  tableData.value.pop();
  tableData.value = tableData.value.concat(e);
  tableData.value.push({});
};

const orderInfo = reactive({
  orderSn: "",
  supplierName: "",
  productName: [],
  createTime: "",
  createUserName: "",
  remark: "",
  orderStatus: 1,
});
const goodsNum = computed(() => {
  let count = 0;
  let totalPrice = 0;
  tableData.value.forEach((item) => {
    count += Number(item.count) || 0;
    totalPrice += Number(item.totalPrice) || 0;
  });
  return {
    count : Number.isInteger(count) ? count : count.toFixed(2),
    totalPrice: totalPrice.toFixed(2),
  };
});
const tableData = ref([{}]);
//确认是否可提交
const canConfirm = computed(() => {
  return (
    tableData.value.every((item, index) => {
      //判断item.count非空不等于0
      if (index === tableData.value.length - 1) return true;
      return (
        item.productId &&
        item.count &&
        item.count != 0 &&
        item.price != undefined
      );
    }) && tableData.value.length > 1
  );
});
const openDetail = () => {
  reactiveAssign(orderInfo, store.state.purchaseOrderInfo);
  orderType.value = orderInfo.orderType || route.params.orderType || 0;
  orderInfoText.value = getConfig(orderType.value).orderInfoText.value;
  getGoodsList();
};
//设置单价
const setPrice = (e, index) => {
  tableData.value[index].price = e.target.value || 0;
  if (!tableData.value[index].count) {
    return;
  }
  tableData.value[index].totalPrice = (
    Number(tableData.value[index].price) * Number(tableData.value[index].count)
  ).toFixed(2);
};
//设置总价
const setTotalPrice = (e, index) => {
  tableData.value[index].totalPrice = e.target.value || 0;
  if (!tableData.value[index].count) {
    return;
  }
  tableData.value[index].price = (
    Number(tableData.value[index].totalPrice) /
    Number(tableData.value[index].count)
  ).toFixed(2);
};
//设置数量
const setNum = (e, index) => {
  tableData.value[index].count = e.target.value || 0;
  if (!tableData.value[index].price) {
    return;
  }
  if(tableData.value[index].price !== 0 && tableData.value[index].price !== '0.00'){
      tableData.value[index].totalPrice = (
        Number(tableData.value[index].price) * Number(tableData.value[index].count)
    ).toFixed(2);
    return
  }
  if(tableData.value[index].totalPrice !== 0 && tableData.value[index].totalPrice !== '0.00'){
    tableData.value[index].price = (
      Number(tableData.value[index].totalPrice) / Number(tableData.value[index].count)
    ).toFixed(2);
  }
};
// const oldList = ref([]);
//获取产品列表
const getGoodsList = () => {
  orderGoodsList({
    orderSn: orderInfo.orderSn,
    orderType: orderType.value,
    orderStatus: 0,
  }).then((res) => {
    if (res.code !== 0) {
      proxy.$message.error(res.msg);
      return;
    }
    let list =res.data.list || []
    // oldList.value = cloneDeep(list) || [];
    tableData.value = resetGoodsList(list, (item) => {
      item.price = proxy.$filters.toYuan(item.price, "元");
      item.totalPrice = proxy.$filters.toYuan(item.totalPrice, "元");
      item.orderType = orderType.value || ''
    });
    tableData.value.push({});
  });
};
const pushGoods = () => {
  tableData.value.push({});
};
//移除
const delItem = (index) => {
  tableData.value.splice(index, 1);
};
//确认编辑
const handleEdit = () => {
  if (!canConfirm.value) {
    proxy.$message.error("请完善产品信息");
    return;
  }
  let list = cloneDeep(tableData.value);
  list.pop();
  list.forEach((item) => {
    // item.price = parseFloat(item.price) * 1000;
    // item.totalPrice = parseFloat(item.totalPrice) * 1000;
  });
  orderAdd({
    orderSn: orderInfo.orderSn,
    remark: orderInfo.remark,
    list: list,
    orderType: orderType.value,
    thirdId: orderInfo.thirdId || "",
    thirdName: orderInfo.thirdName || "",
    stockId: orderInfo.stockId || "",
  }).then((res) => {
    if (res.code === 6999) {
      proxy.$message.error(res.msg);
      return;
    }
    if (res.code !== 0 && res.code !== 6999) {
      ElMessageBox.confirm(res.msg, "提示", {
        confirmButtonText: "确认",
        customClass: "zh-message-box",
        showCancelButton:false ,
      })
        .then(() => {
          proxy.$emit("closeAll");
        })
      return;
    }
    proxy.$message.success("修改成功");
    store.commit("PURCHASE_ORDER_INFO", orderInfo);
    proxy.$emit("close");
  });
};
const close = () => {
  proxy.$emit("close");
};
</script>

<script>
import { reactive, toRefs, ref, watch, getCurrentInstance } from "vue";
import { reactiveAssign } from "@/utils/tool.js";
import { ArrowDown } from "@element-plus/icons-vue";
import SelectGoodsFromList from "@/components/selectGoodsFromList/index.vue";
import SelectByAbcModal from "@/components/selectByAbcModal/index.vue";
export default {
  components: {
    ArrowDown,
    SelectByAbcModal,
    SelectGoodsFromList,
  },
};
</script>
<style lang="scss" scoped>
.goodInfo {
  .zh_content {
    padding: 16px;
    height: 840px;
    box-sizing: border-box;
    position: relative;
    .zh_introduce {
      &.status3 {
        flex-wrap: wrap;
        height: 80px;
        padding: 15px;
        box-sizing: border-box;
        .item {
          width: 33%;
          @include textOverflow(33%);
          margin-bottom: 15px;
        }
      }
    }
    .tabList {
      height: 398px;
      overflow-y: auto;
      @include overFlow();
      padding-top: 20px;
      padding-bottom: 16px;
      box-sizing: border-box;
      .name {
        color: #73737f;
      }
      .text2 {
        color: $fsColor;
      }
      .contBtn {
        font-size: 12px;
        color: $fsColor3;
        display: inline-block;
        cursor: pointer;
      }
      .el-input__inner {
        background-color: #f9fbfb !important;
      }
    }
    .remark {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 178px;
      padding: 0 32px 12px;
      box-sizing: border-box;
      .title {
        line-height: 1;
        padding-left: 6px;
        border-left: 3px solid $fsColor3;
        font-size: 16px;
        color: $fsColor;
      }
      .remark_text {
        text-indent: 2em;
        color: #737480;
        font-size: 14px;
        line-height: 2;
        margin-top: 10px;
      }
      .input {
        margin-top: 16px;
      }
      .btnBox {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        right: 32px;
        bottom: 12px;
        .info_btn {
          width: 112px;
        }
        .select {
          margin: 0 8px;
          width: 160px;
        }
      }
    }
  }
}

:deep(.el-textarea__inner) {
  height: 76px;
  border-radius: 8px;
  background-color: #f9fbfb;
}
</style>
