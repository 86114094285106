import {debounce} from 'lodash'
import {useEventListener} from './event'
import {ref} from 'vue'

const keydownStr = ref('');

export function keydown(e,callback,codeLength) {
  if (e.code === 'Enter') {
    if (keydownStr.value.length === codeLength) {
      callback(keydownStr.value)
    }
    keydownStr.value = '';
  } else {
    keydownStr.value += e.key;
  }
  clearStr()
}
const clearStr = debounce(() => {
  keydownStr.value = ''
}, 100)

export function useKeydown(callback,codeLength = 24) {
  useEventListener(window, 'keydown', (e)=>{
    keydown(e,callback,codeLength)
  })
}


