<template>
  <el-dialog
    v-bind="$attrs"
    class="customerDia"
    :align-center='true'
    width="920"
    :close-on-click-modal="false"
    destroy-on-close
    @open="getGoodsList"
    @close="closeDia"
  >
      <div class="content">
        <div class="queryList">
          <div class="item">
            <div class="query_name">产品名称</div>
            <el-input
                class="w240"
                placeholder="请输入产品名称"
                v-model="formData.query"
                @keyup.enter="getGoodsList"
                v-elInput
            ></el-input>
          </div>
          <div class="item">
            <div class="query_name">产品品牌</div>
            <SelectByModalNew type="brand" @on-change="getGoodsList" v-model="formData.brandId"
                              class="w240 mr_16"></SelectByModalNew>
          </div>
          <div class="item">
            <el-button type="primary" class="searchBtn" @click="getGoodsList">
              <el-icon>
                <Search/>
              </el-icon>
            </el-button>
          </div>
        </div>
        <div class="tagsList" v-if="diaList.length > 0">
          <el-tag
              v-for="tag in diaList"
              :key="tag.modelId + tag.scattered?0:1"
              class="tag1"
              closable
              @close="delDiaListItem(tag)"
          >
            {{ tag.productName }}
          </el-tag>
        </div>
        <div class="tagsList" v-else>
          <div class="text">暂无添加</div>
        </div>
        <div class="search_table">
          <el-table
              :data="tableData"
              style="width: 100%"
              :height="height - 500"
              class="zh-no-table-border"
              :row-class-name="tableRowClassName"
              :cell-style="{ border: 'none' }"
              :header-row-style="{ border: 'none' }"
          >
            <el-table-column
                align="center"
                label="序号"
                width="70"
            >
              <template #default="scope">
                <div class="table_text">{{ scope.$index + 1 }}</div>
              </template>
            </el-table-column>
            <el-table-column
                align="center"
                label="产品名称"
                class-name="table_text"
                show-overflow-tooltip
            >
              <template #default="scope">{{ scope.row.productName }}
              </template>
            </el-table-column>
            <el-table-column
                align="center"
                label="简称"
                width="70"
                show-overflow-tooltip
                class-name="table_text2"
            >
              <template #default="scope">{{ scope.row.subName }}
              </template>
            </el-table-column>
            <el-table-column
                align="center"
                label="产品品牌"
                width="150"
                show-overflow-tooltip
            >
              <template #default="scope">{{ scope.row.brandName }}
              </template>
            </el-table-column>
            <el-table-column
                align="center"
                label="产品单位"
                width="140"
                show-overflow-tooltip
                class-name="table_text2"
            >
              <template #default="scope">
                <div
                    v-html="getUnitName(scope.row)"></div>
              </template>
            </el-table-column>
            <el-table-column
                align="center"
                label="规格/型号"
                width="120"
                show-overflow-tooltip
                class-name="table_text2"
            >
              <template #default="scope">{{ scope.row.modelName }}
              </template>
            </el-table-column>
            <el-table-column
                v-if="showLabelCount()"
                align="center"
                label="可用库存"
                width="120"
                show-overflow-tooltip
                class-name="table_text2"
            >
              <template #default="scope">{{scope.row.scattered ? scope.row.scatteredStockCount : scope.row.stockCount }}
              </template>
            </el-table-column>
            <el-table-column
                prop="model"
                label="操作"
                align="center"
                width="100"
            >
              <template #default="scope">
                <div class="contBtn" @click='handleSelectionChange(scope.$index)'>
                  {{ isIn(scope.row) ? '已添加' : '添加' }}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    <template #footer>
      <el-button @click='closeDia' class="btn1 zh-btn">取消</el-button>
      <el-button class="main btn1" :disabled="!diaList.length" type="primary" @click='saveDiaList'
        >保存</el-button
      >
    </template>
  </el-dialog>
</template>
<script setup>
//引入vue
import {getCurrentInstance, reactive, ref, toRefs,} from "vue";
import {orderGoodsSearch} from '@/utils/api/order.js'
import {useRoute} from "vue-router";
//引入reactive对象重置
//设置模型
import {restProductList, tableRowClassName} from "@/utils/tool.js";
import {getUnitName, uniqueProductList} from '@/utils/tool/orderTools.js'
import {useViewPort} from "@/utils/tool/useVIewPort";
import SelectByModalNew from "@components/form/selectByModalNew/index.vue";
import {cloneDeep} from "lodash";

const { height } = useViewPort();
//引入列表表头配置文件
const { proxy } = getCurrentInstance();
const route = useRoute();
//未绑定产品列表
const tableData = ref([])
const props = defineProps({
  propList:{
    type:Array,
    default:()=>[]
  },
  thirdId: [String, Number],
  stockId: [String, Number]
});
const orderType = route.params.orderType
const showLabelCount = () => {
  let list = ["6", "7", "8", "9", "10", "11", "12",]
  return list.includes(orderType)
}
const emits = defineEmits(["close", "save"]);
const {propList,thirdId,stockId} = toRefs(props)
const formData = reactive({
  query: "",
  brandName: "",
  brandId: "",
})
const diaList = ref([])

//删除绑定产品
const delDiaListItem = (e)=>{
  let index = diaList.value.findIndex((item) => item.modelId === e.modelId && item.scattered === e.scattered)
  diaList.value.splice(index,1)
  tableData.value.push(cloneDeep(e))
}

//添加绑定商品
const handleSelectionChange = (e)=>{
  let item =cloneDeep(tableData.value[e])
  diaList.value.push(item)
  tableData.value.splice(e,1)
}


//查询产品列表
function getGoodsList(){
  orderGoodsSearch({
    query:formData.query,
    brandId:formData.brandId,
    thirdId:thirdId.value,
    orderType:orderType || undefined,
    stockId:stockId.value
  }).then(res=>{
    if (res.code !== 0) {
        proxy.$message.error(res.msg);
        return;
      }
      let tableList = res.data.list || []
    tableList = restProductList(tableList)
      let list = uniqueProductList(tableList, propList.value)
      tableData.value =uniqueProductList(list , diaList.value)
  })
}


//判断批量添加是否已添加到列表
const isIn = (e)=>{
  let flag = false;
  diaList.value.forEach((item)=>{
    if (item.modelId === e.modelId && item.scattered === e.scattered && item.productId === e.productId) {
      flag = true;
    }
  })
  return flag;
}

//确认保存列表
const saveDiaList = ()=>{
  if(diaList.value.length){
    diaList.value.forEach((item)=>{
      if(item.price){
        item.price = ''
      }
      if(item.totalPrice){
        item.totalPrice = ''
      }
      if(item.count){
        item.count = ''
      }
    })
  }
  emits('save',diaList.value)
  closeDia()
}


//取消弹窗
const closeDia = ()=>{
    proxy.$emit('close')
    diaList.value = []
    formData.query = ''
    formData.brandId = ''
    tableData.value = []
}
</script>
<script>
import { ref, getCurrentInstance, watch, useAttrs } from "vue";
import SelectByAbcModal from '@/components/selectByAbcModal/index.vue'
import { Search } from "@element-plus/icons-vue";
import { fa } from "element-plus/es/locale";
export default {
  name: "customerDia",
  components: {
    Search,
    SelectByAbcModal
  },
};
</script>
<style lang="scss">
@import "./bind.scss";
</style>
