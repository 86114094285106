<template>
  <!-- 产品详情弹窗 -->
  <div>
    <el-dialog
    v-bind="$attrs"
    title="查看明细"
    width="920"
    class="goodInfo"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="closeDia"
    destroy-on-close
    align-center
    @open="openDia"
  >
  <div class="zh_content">
    <div class="info">
      <div class="zh_title">产品信息</div>
      <div class="zh_goods_info dp_f">
        <div class="zh_goods_img">
          <img :src="goodsInfo.img" class="zh_pic">
        </div>
        <div class="zh_goods">
            <div class="zh_goods_name">{{goodsInfo.productName}} <span class="zh_line">/</span> <span class="zh_unit">{{goodsInfo.unitName}}</span></div>
            <div class="zh_goods_item dp_f">
              <div class="zh_goods_item_l">
                <span class="zh_goods_title">产品品牌：</span>
                <span class="zh_goods_text">{{goodsInfo.brandName || '--'}}</span>
              </div>
              <div class="zh_goods_item_l">
                <span class="zh_goods_title">规格/型号：</span>
                <span class="zh_goods_text">{{goodsInfo.modelName || '--'}}</span>
              </div>
            </div>
            <div class="zh_goods_item dp_f">
              <div class="zh_goods_item_l">
                <span class="zh_goods_title">产品条码：</span>
                <more-list :list='goodsInfo.encoded' v-if='goodsInfo.encoded'></more-list>
                <span class="zh_goods_text" v-else>--</span>
              </div>
              <div class="zh_goods_item_l">
                <span class="zh_goods_title">合计数量/金额(元)：</span>
                <span class="zh_goods_text">{{goodsInfo.checkCount}} / <span class="unit">¥</span>{{$filters.toYuan( goodsInfo.checkPrice,'元')}}</span>
              </div>
            </div>
        </div>
      </div>
       <div class="zh_title">详细信息</div>
       <div class="tabList">
        <el-table
        :data="tableData"
        style="width: 100%"
        height="100%"
         class="zh-no-table-border"
        :row-class-name="tableRowClassName"
        :cell-style="{border:'none'}"
        :header-row-style="{border:'none'}"
        @row-click="showRfid"
      >
        <el-table-column
          align="center"
          label="序号"
          width="100"
        >
        <template #default='scope'>
          <div class="table_text" >{{scope.$index + 1}}</div>
        </template>
       </el-table-column>
          <!-- <el-table-column
              align="center"
              label="标签状态"
          >
            <template #default='scope'>
              <div class="error_type" >
                <div class="err_type" :class="scope.row.labelStatus == 0?'status2':''">
                  {{scope.row.labelStatusDesc}}
                </div>
              </div>
            </template>
          </el-table-column> -->
       <el-table-column
          align="center"
          label="标签序列号"
        >
        <template #default='scope'>
          <div class="table_text2" >{{scope.row.serial}}</div>
        </template>
       </el-table-column>
       <el-table-column
          align="center"
          label="失效日期"
        >
        <template #default='scope'>
          <div class="table_text" v-if="scope.row.outTime != 0">{{$filters.timeFormat(scope.row.outTime,2)}}</div>
          <div class="table_text" v-else>--</div>
        </template>
       </el-table-column>
        </el-table>
      </div>

    </div>
  </div>
  </el-dialog>
  <rfidHistory v-model="historyShow" v-model:rfidInfo="rfidInfo" @handleClose="closeRfid"></rfidHistory>
  </div>

</template>


<script setup>
import MoreList from '@/components/moreList/moreList.vue';
import {tableRowClassName} from '@/utils/tool.js'
import { labelBindList } from '@/utils/api/rfid.js'
import { reactive, toRefs, ref, watch,getCurrentInstance,defineEmits,defineProps } from "vue";
const props = defineProps({
  goodsInfo:{
    type:Object,
    default:()=>{}
  }
})
const { goodsInfo } = toRefs(props)
const { proxy } = getCurrentInstance()


//请求列表接口
const getRfidList = ()=>{
  labelBindList({
    orderSn:goodsInfo.value.orderSn,
    productId:goodsInfo.value.productId,
    modelId:goodsInfo.value.modelId,
  }).then(res=>{
    if(res.code != 0){
      proxy.$message.error(res.msg)
      return
    }
    tableData.value = res.data?.list || []
  })
}
//弹窗打开产品列表接口
const openDia = ()=>{
  getRfidList()
  rfidInfo.orderSn = goodsInfo.value.orderSn
  rfidInfo.productId = goodsInfo.value.productId
  rfidInfo.modelId = goodsInfo.value.modelId
}
const getGoodsInfo = ()=>{
  return goodsInfo.value
}
const emit = defineEmits(['handleClose'])

const tableData = ref([])

const historyShow = ref(false)

const closeRfid =()=>{
  historyShow.value = false
}

const rfidInfo = reactive({
  labelId:'',
  rfid:"",
  serial:'',
  orderSn:goodsInfo.value.orderSn,
  productId:goodsInfo.value.productId,
  modelId:goodsInfo.value.modelId,
})

const showRfid = (row)=>{
  rfidInfo.labelId = row.labelId
  rfidInfo.rfid = row.rfid
  rfidInfo.serial = row.serial
  historyShow.value = true
}

const closeDia = (done)=>{
  emit('handleClose',{})
  done()
}


</script>
<script>
import { reactive, toRefs, ref, watch,getCurrentInstance } from "vue";
import { ArrowDown } from '@element-plus/icons-vue'
import rfidHistory from './rfidHistory.vue'
export default {
  components: {
    ArrowDown,
    rfidHistory
  },
};
</script>
<style lang="scss" scoped>
.goodInfo {
  .zh_content{
    padding: 16px;
    height: 824px;
    box-sizing: border-box;
    position: relative;
    .zh_goods_info{
      border-radius: 8px;
      background-color: #FAFBFC;
      padding: 24px;
      margin-top: 16px;
      margin-bottom: 24px;
      .zh_goods_img{
        width: 144px;
        height: 144px;
        border-radius: 8px;
        overflow: hidden;
        .zh_pic{
          width: 100%;
          height: 100%;
        }
      }
      .zh_goods{
        width: 624px;
        color: $fsColor;
        .zh_goods_name{
          margin: 25px 0;
          line-height: 1;
          font-size: 22px;
          .zh_line{
            display: inline-block;
            margin: 0 6px;
            font-size: 18px;
          }
          .zh_unit{
            font-size: 18px;
          }
        }
        .zh_goods_item{
          margin-bottom: 24px;
          .zh_goods_item_l{
            display: flex;
            align-items: center;
            flex: 1;
            flex-wrap: nowrap;
            line-height: 1.3;
            >span{
              display: inline-block;
            }
            .zh_goods_title{
              color: #737480;
            }
            .zh_goods_text{
              @include textOverflow(200px);
              .unit{
                display: inline-block;
                margin-right:2px;
              }
            }
          }
        }
      }
    }
    .zh_introduce{
      margin-top: 0;
      flex-wrap: wrap;
      padding-top: 16px;
      height: auto;
      .item{
        width:max-content;
        margin-right: 40px;
        margin-bottom: 16px;
      }
    }
    .tabList{
    margin-top: 16px;
    height:520px;
    padding-top: 20px;
    padding-bottom: 16px;
    box-sizing: border-box;
    .name{
      color: #73737F;
    }
    .text2{
      color: $fsColor;
    }
  }

  }
}

</style>
