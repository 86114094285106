<template>
  <div class="warehous" v-loading="loading">
    <div class="census">
      <div class="item">
        <div class="title">订单合计</div>
        <div class="num">{{ census.total }}</div>
      </div>
      <div class="item">
        <div class="title">产品数量</div>
        <div class="num">{{ census.totalNum }}</div>
      </div>
      <div class="item">
        <div class="title">合计金额</div>
        <div class="num">
          {{ $filters.toYuan(census.totalPrice, "元") }}
          <span class="unit">元</span>
        </div>
      </div>
    </div>
    <div class="queryBoxOne">
      <div class="btnBox">
        <el-select placeholder="请选择仓库" popper-class="no-border-select-popper" class="no-boder-select bdr"
                   v-model="formData.stockId" @change="getList">
          <el-option v-for="item in sotckList" :key="item.stockId"  :label="item.stockName" :value="item.stockId"  />
        </el-select>
      </div>
      <div class="queryItem">
        <el-date-picker
            v-model="formData.time"
            type="daterange"
            class="w280"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="getList"
            clearable
        />
        <SelectByModalNew :type="searchModal.typeName" @on-change="getList" v-model="formData.thirdId"
                          class="w200"></SelectByModalNew>
        <el-input
        v-elInput
          v-model="formData.query"
          class="w200"
          placeholder="产品名称/简称/条码"
          @keydown.enter="getList"
          clearable
        />
          <el-input
          v-elInput
          v-model="formData.orderSn"
          class="w200"
          placeholder="订单编号"
          @keydown.enter="getList"
          clearable
        />
        <div class="queryBtn">
          <el-button type="primary" @click="getList"
            ><el-icon><Search /></el-icon
          ></el-button>
          <el-button @click="fieldCheckShow">展示项设置</el-button>
        </div>
      </div>
    </div>
    <div class="tabList">
      <!-- 列表 -->
      <el-table
        :data="tableData"
        style="width: 100%"
        @row-click="checkGoods"
        height="100%"
        @sort-change="changeSort"
        class="zh-no-table-border"
        :row-class-name="tableRowClassName"
        :cell-style="{ border: 'none' }"
        :header-row-style="{ border: 'none' }"
      >
      <el-table-column
              align="center"
              label="序号"
              width="100"
            >
            <template #default="{$index,row}">
              <div class="qrcode-num">
                <el-popover
                v-if="row.createType === 2"
                placement="top-start"
                title="提示"
                trigger="hover"
                content="二维码生成订单"
              >
                <template #reference>
                  <el-icon class="icon" ><StarFilled /></el-icon>
                </template>
              </el-popover>
                <div>
                  {{ $index  + 1 }}
                </div>
              </div>
              </template>
            </el-table-column>
        <template v-for="item in titleListIn" :key="item.fieldKey">
          <template v-if="item.isShow">
            <el-table-column
              v-if="item.fieldKey == 'productName'"
              label="产品名称"
              align="center"
              width="340"
            >
              <template #default="scope">
                <div class="nameBox">
                  <template
                    v-for="(item, index) in scope.row.productName"
                    :key="item"
                  >
                    <span class="name" v-if="index < 3"> {{ item }} </span>
                  </template>

                  <span class="more" v-if="scope.row.productName.length > 3"
                    >+{{ scope.row.productName.length - 3 }}</span
                  >
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-else-if="item.fieldKey == 'count'"
              :prop="item.fieldKey"
              :label="item.title"
              :width="240"
              :sortable="item.sortable"
              :class-name="item.className || 'table_text2'"
              align="center"
            >
              <template #default="scope">
                {{scope.row.count}} / {{scope.row.realityCount}}
              </template>
            </el-table-column>
            <el-table-column
              v-else-if="item.fieldKey == 'totalPrice'"
              :prop="item.fieldKey"
              :label="item.title"
              :width="240"
              :sortable="item.sortable"
              :class-name="item.className || 'table_text2'"
              align="center"
            >
              <template #default="scope">
                {{scope.row.totalPrice}} / {{scope.row.realityTotalPrice}}
              </template>
            </el-table-column>
            <el-table-column
              v-else-if="item.fieldKey == 'remark'"
              align="center"
              :prop="item.fieldKey"
              :label="item.title"
              :width="item.width"
              :sortable="item.sortable"
              :class-name="item.className || 'table_text2'"
              show-overflow-tooltip
            >
            <template #default="scope">
                {{ scope.row.remark || "" }}
              </template>
            </el-table-column>
            <el-table-column
              v-else
              align="center"
              :prop="item.fieldKey"
              :label="item.title"
              :width="item.width"
              :sortable="item.sortable"
              :class-name="item.className || 'table_text2'"
              show-overflow-tooltip
            >
            </el-table-column>
          </template>
        </template>
      </el-table>
    </div>
    <div class="mainPage">
      <el-pagination
        v-model:current-page="pageInfo.pageNumber"
        layout="total, prev, pager, next , jumper"
        :total="pageInfo.total"
        v-model:page-size="pageInfo.pageSize"
        @current-change="handleCurrentChange"
      />
    </div>
    <FieldsCheck
      :list="titleListIn"
      v-model="customFields"
      @close="checkFileds"
    />
    <!-- 订单详情 -->
    <OrderInfo
      v-model="orderShow"
      @close="closeDetail"
    ></OrderInfo>
  </div>
</template>
<script setup>
import {tableRowClassName} from "@/utils/tool.js";
import {getCurrentInstance, onMounted, reactive, ref,} from "vue";
import {useStore} from "vuex";
import {productList} from "@/utils/api/product/product.js";
import {useRoute, useRouter} from "vue-router";
import {stockList} from '@/utils/api/procurement/stock.js'
import {orderList} from '@/utils/api/order.js'
import {getConfig} from '../uitl'
import {usePagination} from "@utils/tool/page";
import {getDomHeight} from "@utils/tool/getDomHeight";
import SelectByModalNew from "@components/form/selectByModalNew/index.vue";

const {domHeight} = getDomHeight('.queryBoxOne');
const route = useRoute();
const router = useRouter();
const { proxy } = getCurrentInstance();
const store = useStore();
const loading = ref(false);
const orderType = route.params.orderType || 0;
const sotckList = ref([])
//控组详情弹窗是否展示
const orderShow = ref(false);
//获取仓库列表
const getStockListData = async () => {
const res = await stockList()
  if (res.code === 0) {
    if (res.data?.list?.length === 1) {
    formData.stockId = res.data.list[0].stockId
    sotckList.value = res.data.list
  }else if(res.data?.list?.length  && res.data?.list?.length > 1){
    sotckList.value.push({stockId:"",stockName:'全部仓库'})
    sotckList.value.push(...res.data.list)
  }
  getList();
}else{
  proxy.$message.error(res.msg)
}
}
/*
  关于统计
*/
const census = reactive({
  total: 0,
  totalNum: 0,
  totalPrice: 0,
});
const formData = reactive({
  query: "",
  thirdId: '',
  time: [],
  stockId:"",
  createTimeBegin: "",
  createTimeEnd: "",
  orderSn: "",
});
const {pageInfo} = usePagination()
//获取配置文件
const { titleListIn,searchModal } = getConfig(orderType)
const tableData = ref([]);
const customFields = ref(false);
const fieldCheckShow = () => {
  customFields.value = true;
};
const checkFileds = (list) => {
  customFields.value = false;
};
const getList = () => {
  loading.value = true;
    let beginTime = 0
  let endTime = 0
  if(formData.time && formData.time[0]){
    beginTime = moment(formData.time[0]).format('YYYY-MM-DD') + ' 00:00:00'
  }
  if(formData.time && formData.time[1]){
    endTime = moment(formData.time[1]).format('YYYY-MM-DD') + ' 23:59:59'
  }
  let obj = {
    beginTime:moment(beginTime).valueOf(),
    endTime:moment(endTime).valueOf(),
    thirdId: formData.thirdId,
    query: formData.query,
    pageSize: pageInfo.pageSize,
    pageNumber: pageInfo.pageNumber,
    sortField: formData.sortField,
    sortType: formData.sortType,
    orderType:orderType,
    orderStatus:1,
     stockId:formData.stockId,
    orderSn:formData.orderSn
  };
  orderList(obj)
    .then((res) => {
      if (res.code != 0) {
        proxy.$message.error(res.msg);
        return;
      }
      tableData.value = res.data?.list || [];
      tableData.value.forEach((item) => {
        item.price = proxy.$filters.toYuan(item.price, "元");
        item.totalPrice = proxy.$filters.toYuan(item.totalPrice, "元");
        item.realityTotalPrice = proxy.$filters.toYuan(item.realityTotalPrice, "元");
        item.time = proxy.$filters.timeFormat(item.time, 1);
        if (item.productName) {
          item.productName = item.productName.split(",") || [];
        } else {
          item.productName = [];
        }
        if(item.scattered){
          item.unitName = item.scatteredName
        }
      });
      pageInfo.total = res?.data?.total || 0;
      census.total = res.data?.census?.total || 0;
      census.totalNum = res.data?.census?.totalNum || 0;
      census.totalPrice = res.data?.census?.totalPrice || 0;
      proxy.$emit("change");
      setTimeout(() => {
        loading.value = false;
      }, 100);
    })
    .finally(() => {
      loading.value = false;
    });
};
const changeSort = ({ column, prop, order }) => {
  if (!order) {
    formData.sortField = "";
    formData.sortType = "";
  } else {
    formData.sortField = prop;
    formData.sortType = order == "descending" ? "desc" : "asc";
  }
  getList();
};
//点击采购订单详情
const checkGoods = (e) => {
  store.commit("PURCHASE_ORDER_INFO", e);
  orderShow.value = true;
};
const handleCurrentChange = (val) => {
  pageInfo.pageNumber = val;
  getList();
};
onMounted(() => {
  getStockListData()
});
const closeDetail = () => {
  orderShow.value = false;
  getList();
};
const add = () => {
  proxy.$emit("add");
};
</script>

<script>
import { Search, Plus, ArrowDown } from "@element-plus/icons-vue";
import { reactive, toRefs, ref } from "vue";
import { productList } from "@/utils/api/product/product.js";
import { cloneDeep } from "lodash";
import FieldsCheck from "@/components/fieldsCheck/index.vue";
import OrderInfo from "./inStockInfo.vue";
import SelectByAbcModal from "@/components/selectByAbcModal/index.vue";
import moment from "moment";
export default {
  name: "warehous",
  components: {
    Search,
    Plus,
    FieldsCheck,
    OrderInfo,
    ArrowDown,
    SelectByAbcModal,
  },
};
</script>

<style lang="scss" scoped>
@import "../index.scss";
.warehous {
  padding-top: 8px;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  padding-bottom: 70px;
  .tabList {
    margin-top: 20px;
    height: calc(100% - 120px - v-bind(domHeight));
  }
  .queryBoxOne {
    .add_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      .icon{
        font-size: 14px;
      }
      .add_text {
        font-size: 14px;
        margin-left: 4px;
        height: 12px;
        line-height: 1;
        // position: relative;
        // top: 0.5px;
      }
    }
  }
  .imgList {
    img {
      width: 56px;
      height: 42px;
      border-radius: 8px;
      overflow: hidden;
    }
    .nameBox {
      display: flex;
      align-items: center;
      justify-content: center;
      .name {
        margin-right: 10px;
        font-size: 14px;
        display: inline-block;
        @include textOverflow(5em);
      }
      .more {
        color: #73737f;
      }
    }
    .statusBtn {
      display: flex;
      justify-content: center;
    }
    .status {
      font-size: 12px;
      color: #3759ff;
      width: 56px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      background-color: rgba(55, 89, 255, 0.1);
      border-radius: 4px;
      &.status2 {
        color: #ff7b5d;
        background-color: rgba(255, 123, 93, 0.1);
      }
    }
  }
  .queryBoxOne {
    margin-top: 16px;
  }
}

:deep(.el-range-separator) {
  font-weight: 400;
  color: $fsColor2;
}
</style>
