<template>
  <!-- 产品详情弹窗 -->
  <el-dialog
    :modelValue="props.modelValue"
    title="对应产品"
    width="920"
    class="relevance"
    :close-on-click-modal="false"
    destroy-on-close
    align-center
    :before-close="handleClose"
    @open="openDetail"
  >
    <div class="relevance-modal">
      <div class="goods-info">
        <div class="goods-img">
          <GoodImg :src="productInfo.img"></GoodImg>
        </div>
        <div class="goods-introduce">
          <div class="name">
            <span class="name-text">{{productInfo.productName}}</span>
            <span class="line">/</span>
            <span class="unit" v-html="getUnitName(productInfo)"></span>
          </div>
          <div class="zh-introduce">
            <div class="item">
              <span class="key"> 产品品牌： </span>
              <span class="value"> {{productInfo.brandName || "--"}} </span>
            </div>
            <div class="item">
              <span class="key"> 规格/型号： </span>
              <span class="value">  {{productInfo.modelName || "--"}} </span>
            </div>
            <div class="item">
              <span class="key"> 产品条码： </span>
              <span class="value">  {{productInfo.encoded?.[0] || "--"}} </span>
            </div>
          </div>
        </div>
      </div>
      <div class="queryBoxOne mt_16">
        <div class="btnBox"></div>
        <form class="queryItem" @submit.prevent="getList">
          <el-input
            v-model="formData.query"
            class="w200"
            placeholder="产品名称/简称/条码"
            clearable
            v-elInput
          />
          <div class="queryBtn">
            <el-button type="primary" class="square" @click="getList">
              <el-icon><Search /></el-icon
            ></el-button>
          </div>
        </form>
      </div>
      <div class="tabList" >
          <el-table :data="tableData" class="zh-no-table-border"
                    :row-class-name="tableRowClassNameRelieve"
                    :cell-style="{border:'none'}"
                    :header-row-style="{border:'none'}" style="width: 100%" height="100%">
            <el-table-column
                type="index"
                width="70"
                label="序号"
                align="center"
            />

            <el-table-column
                width="100"
                align="center"
                class-name="table_text2"
                show-overflow-tooltip
            >
              <template #header>
                <div>产品图片</div>
              </template>
              <template #default="scope">
                <div style="display: flex;align-items: center;justify-content: center;">
                  <GoodImg size="48" :src="scope.row.img"></GoodImg>
                </div>
              </template>
            </el-table-column>
            <el-table-column
                align="left"
                width="200"
                class-name="table_text2"
                show-overflow-tooltip
            >
              <template #header>
                <div>产品名称</div>
              </template>
              <template #default="scope">
                <div> {{scope.row.productName }}</div>
                <div class="table_text mt_16" v-if="scope.row.equalGoods">
                 <span class="sub_text">已对应：</span> {{scope.row.equalGoods.productName}}</div>
              </template>
            </el-table-column>
            <el-table-column
                align="left"
                class-name="table_text"
                label="简称"
                show-overflow-tooltip
            >
              <template #default="scope">
                <div> {{scope.row.subName || "--" }}</div>
                <div class="table_text mt_16" v-if="scope.row.equalGoods"> {{scope.row.equalGoods.subName}}</div>
              </template>
            </el-table-column>
            <el-table-column
                align="left"
                class-name="table_text"
                label="产品品牌"
                show-overflow-tooltip
            >
              <template #default="scope">
                <div> {{scope.row.brandName || "--" }}</div>
                <div class="table_text mt_16" v-if="scope.row.equalGoods">{{scope.row.equalGoods.brandName}}</div>
              </template>
            </el-table-column>
            <el-table-column
                align="left"
                class-name="table_text2"
                show-overflow-tooltip
                label="产品单位"
            >
              <template #default="scope">
                <div v-html="getUnitName(scope.row)"></div>
                <div class="table_text mt_16" v-if="scope.row.equalGoods" v-html="getUnitName(scope.row.equalGoods)"></div>
              </template>
            </el-table-column>
            <el-table-column
                align="left"
                class-name="table_text2"
                label="规格/型号"
                show-overflow-tooltip
            >
              <template #default="scope">
                <div> {{scope.row.modelName || "--" }}</div>
                <div class="table_text mt_16" v-if="scope.row.equalGoods">{{scope.row.equalGoods.modelName}}</div>
              </template>
            </el-table-column>
            <el-table-column  align="left">
              <template #header>
                <div>操作</div>
              </template>
              <template #default="scope">
                <span
                  v-if="!isBind(scope.row)"
                    class="contBtn"
                    @click="bindProduct(scope.row)"
                    >对应</span
                  >
                  <span
                  v-else
                  @click="unBindProduct(scope.row)"
                    class="red"
                    >解除对应</span
                  >
              </template>
            </el-table-column>
          </el-table>
        </div>
    </div>
  </el-dialog>
</template>
<script setup>
import { ref, reactive ,toRefs,inject } from "vue";
import {tableRowClassNameRelieve} from "@/utils/tool.js";
import GoodImg from "@/components/goodImg/index.vue";
import {getUnitName} from "@utils/tool/orderTools";
import {orderCodeProductList ,productInfoEqual ,productInfoEqualRelieve} from '@/utils/api/order.js';
import message from "@/utils/tool/message";
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  productInfo:{
    type:Object,
    default:()=>({})
  }
})
const orderInfo = inject("orderInfo")
const emit = defineEmits(["update:modelValue",'confirm'])
const {productInfo } = toRefs(props)
const formData = reactive({
  query: "",
});
const tableData = ref([])
const openDetail =async ()=>{
formData.query = ""
 await getList()
}
const handleClose = ()=>{
  emit("update:modelValue", false)
}
//获取产品列表
const getList = async()=>{
 const {data,msg,code} = await orderCodeProductList({...formData,orderType:orderInfo.value.orderType,otherCompanyId:orderInfo.value.census?.companyId || ''})
 if(code !== 0 ){
  message.error(msg)
  return
 }
 tableData.value = data?.list || []
}
const isBind = (row)=>{
 return row.equalGoods
}
const getParams = (row)=>{
  return {
    ownerProductId:row.productId,
    ownerModelId:row.modelId,
    ownerScattered:row.scattered,
    otherCompanyId:orderInfo.value.census?.companyId || '',
    otherProductId:productInfo.value.productId,
    otherModelId:productInfo.value.modelId,
    otherScattered:productInfo.value.scattered,
  }
}
//对应产品
const bindProduct = async(row)=>{
  const {msg,code} = await productInfoEqual(getParams(row))
  if(code !== 0 ){
    message.error(msg)
    return
  }
  emit("confirm",{...productInfo.value ,equalGoods:row})
  getList()
  message.success("对应成功")
}

//解除对应
const unBindProduct = async(row)=>{
  const {msg,code} = await productInfoEqualRelieve({
    otherCompanyId:orderInfo.value.census?.companyId || '',
    productId:row.productId,
    modelId:row.modelId,
    scattered:row.scattered,
  })
  if(code !== 0 ){
    message.error(msg)
    return
  }
  emit("confirm",{...row ,equalGoods:null})
  getList()
  message.success("解除对应成功")
}
</script>

<style lang="scss" scoped>
@import "./relevanceModal.scss";
</style>
