<template>
  <!-- 产品详情弹窗 -->
  <el-dialog
    v-bind="$attrs"
    title="关联RFID标签"
    width="1400"
    class="goodInfo"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="closeDia"
    destroy-on-close
    align-center
    @open="openDia"
  >
    <div class="zh_content">
      <div class="info">
        <div class="zh_introduce">
          <div class="single">
            <div class="item">
              <div class="text">产品名称：</div>
              <div class="desc">{{goodsInfo.productName || '--'}}</div>
            </div>
            <div class="item">
              <div class="text">简称：</div>
              <div class="desc">{{goodsInfo.subName || '--'}}</div>
            </div>
            <div class="item">
              <div class="text">产品品牌：</div>
              <div class="desc">{{goodsInfo.brandName || '--'}}</div>
            </div>
            <div class="item">
              <div class="text">产品单位：</div>
              <div class="desc">{{goodsInfo.unitName || '--'}}</div>
            </div>
            <div class="item">
              <div class="text">规格/型号：</div>
              <div class="desc">{{goodsInfo.modelName || '--'}}</div>
            </div>
            <div class="item">
              <div class="text">单价：</div>
              <div class="desc">￥{{ $filters.toYuan(goodsInfo.price,'元') }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="tabList">
        <div class="isDoneList">
          <div class="table_title">
            <div class="title">已绑列表</div>
          </div>
          <el-table :data="tableData" height="602" :row-class-name="tableRowClassName1" @row-click="showRfid"   class="zh-no-table-border"  :cell-style="{border:'none'}"
          :header-row-style="{border:'none'}">
             <el-table-column
              type="index"
              width="100"
              label="序号"
              align="center"
              fixed
            />
            <el-table-column align="center" label="标签序列号">
              <template #default='scope'>
               <div class="table_text2" >{{scope.row.serial}}</div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="失效日期" width="170">
              <template #default='scope'>
                   <div class="table_text" v-if='goodsInfo.followTime'>{{$filters.timeFormat(scope.row.outTime,2)}}</div>
                   <div class="table_text" v-else>--</div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
              <template #default="scope">
                <div class="contBtn" @click="unBind(scope.row,2)">解绑</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="willDoneList">
          <div class="table_title">
            <div class="title">
              当前绑定
              <span class="text"
                >（使用扫描枪，扫描标签关联产品）</span
              >
            </div>
            <div class="btn">
            </div>
          </div>
          <el-table
            :data="addList"
            height="602"
            :row-class-name="tableRowClassName"
            class="zh-no-table-border"
            :cell-style="{border:'none'}"
            :header-row-style="{border:'none'}"
            @row-click="showError"
          >
            <el-table-column
              type="index"
              label="序号"
              align="center"
              width="60"
              fixed
            />
            <el-table-column align="center" :width="goodsInfo.followTime?'100':'450'" label="标签序列号">
              <template #default='scope'>
                <div class="num">{{scope.row.serial}}</div>
              </template>
            </el-table-column>
            <el-table-column align="center" width="400" v-if='goodsInfo.followTime'>
              <template #header>
                <div class="timeChooseBox" v-if="goodsInfo.validityPeriodType == 1 || goodsInfo.validityPeriodType == 3">
                  <template v-if='goodsInfo.validityPeriodType != 3'>
                    <span class="red">*</span> <span class="text">失效日期</span>
                  </template>
                  <el-date-picker
                    type="date"
                    v-model="formData.time"
                    placeholder="请选择时间"
                    @change="changeTotalTime"
                  />
                </div>
                <div class="timeChooseBox" v-else>
                  <span class="red">*</span> <span class="text">生产时间</span>
                  <el-date-picker
                    class="w128"
                    type="date"
                    v-model="formData.time"
                    placeholder="请选择时间"
                    @change="changeTotalTime"
                  />
                  <span class="red">*</span> <span class="text">保质期</span>
                  <span class="timeChoose">
                    <el-input
                    v-elInput
                      v-number
                      maxlength="3"
                      class="timeChooseInput nbd"
                      v-model="goodsInfo.qualityGuaranteePeriodNum"
                      @change='changeTotalTime'
                      @blur="goodsInfo.qualityGuaranteePeriodNum = $event.target.value"
                    ></el-input>
                    <el-select
                      placeholder="年"
                      v-model="goodsInfo.qualityGuaranteePeriodType"
                      class="nbd timeChooseInput2"
                      @change='changeTotalTime'
                    >
                      <el-option label="天" :value="1" />
                      <el-option label="月" :value="2" />
                      <el-option label="年" :value="3" />
                    </el-select>
                  </span>
                </div>
              </template>
              <template #default='scope'>
                <el-date-picker
                  v-if='scope.row.labelStatus == 0'
                  class="w128"
                  type="date"
                  placeholder="请选择时间"
                  v-model="addList[scope.$index].outTime"
                />
                <div class="table_text" v-else>{{$filters.timeFormat(scope.row.outTime,2) }}</div>
              </template>
            </el-table-column>
            <el-table-column align="center" width="120" label="标签状态">
              <template #default='scope'>
                <div class="rfidTags success" v-if='scope.row.labelStatus == 0'>正常</div>
                <div class="rfidTags isOther" v-if='scope.row.labelStatus == 2'>已绑定其他商品</div>
                <div class="rfidTags isIn" v-if='scope.row.labelStatus == 1'>已绑列表已存在</div>
                <div class="rfidTags in" v-if='scope.row.labelStatus == 3'>已入库</div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
              <template #default="scope">
                <div class="contBtn" @click="delRfid(scope.$index)">移除</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="btnBox">
        <el-button @click="closeAdd">关闭</el-button>
        <el-button type="primary" @click="handleBind" :disabled='isOk'>确认绑定</el-button>
      </div>
    </div>
  </el-dialog>
  <el-dialog
    v-model="showDia"
    :title="rfidChooseInfo.title"
    width="360"
    class="rfidInfo"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    destroy-on-close
    align-center
  >
    <div class="content">
      <div class="item">
        <div class="name">标签序列号：</div>
        <div class="desc">{{rfidChooseInfo.serial}}</div>
      </div>
      <div class="item">
        <div class="name">所属采购订单：</div>
        <div class="desc">{{rfidChooseInfo.orderSn}}</div>
      </div>
      <div class="item">
        <div class="name">订单状态：</div>
        <div class="rfidTags success">{{rfidChooseInfo.orderStatusDesc}}</div>
      </div>
      <div class="item">
        <div class="name">产品品牌：</div>
        <div class="desc">{{rfidChooseInfo.brandName}}</div>
      </div>
      <div class="item">
        <div class="name">产品名称：</div>
        <div class="desc">{{rfidChooseInfo.productName}}</div>
      </div>
      <div class="item">
        <div class="name">规格/型号：</div>
        <div class="desc">{{rfidChooseInfo.modelName}}</div>
      </div>
      <div class="item">
        <div class="name">产品状态：</div>
        <div class="rfidTags" :class="rfidChooseInfo.stockStatus?'in':'out'">{{rfidChooseInfo.stockStatusDesc}}</div>
      </div>
      <div class="item">
        <div class="name">入库时间：</div>
        <div class="desc">{{$filters.timeFormat(rfidChooseInfo.updateTime)}}</div>
      </div>
      <div class="btnBox dp_f" v-if='rfidChooseInfo.labelStatus == 2'>
        <el-button @click="showDia = false">取消</el-button>
        <el-button type="primary" @click="unBind(rfidChooseInfo)">确认解除原有关系</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script setup>
import {computed, getCurrentInstance, reactive, ref, toRefs} from "vue";
import {keydown} from '@/utils/tool/keydown.js'
import {ElMessageBox} from "element-plus";
import {labelBind, labelBindInfo, labelBindList, labelUnbind} from '@/utils/api/rfid.js';
import moment from "moment";

const { proxy } = getCurrentInstance();
const beginTimeType = ref(0)
const props = defineProps({
  goodsInfo: {
    type: Object,
    default: () => {},
  },
});
const emit = defineEmits(["handleClose"]);
const { goodsInfo } = toRefs(props);
//请求列表接口
const getRfidList = () => {
  labelBindList({
    orderSn: goodsInfo.value.orderSn,
    productId: goodsInfo.value.productId,
    modelId: goodsInfo.value.modelId,
  }).then((res) => {
    if (res.code != 0) {
      proxy.$message.error(res.msg);
      return;
    }
    tableData.value = res.data?.list || [];
  });
};

const tableData = ref([
]);
const addList = ref([])
const formData = reactive({
  name: "",
  num: "",
  val: "1",
  time:""
});
const showDia = ref(false); // 控制弹窗是否展示
const closeDia = (done) => {
  closeAdd()
  done();
};
const tableRowClassName = ({ row, rowIndex }) => {
  if (row.labelStatus === 0) {
    if (rowIndex%2==0) {
      return "raido-row";
    } else {
      return "event-row";
    }
  } else if (row.labelStatus === 2) {
    return "in-row";
  } else if (row.labelStatus === 1) {
    return "other-row";
  } else if (row.labelStatus === 3) {
    return "instock-row";
  }
};
const tableRowClassName1 = ({ row, rowIndex }) => {
  if (rowIndex%2==0) {
    return "raido-row";
  } else {
    return "event-row";
  }
};
const rfidChooseInfo = ref({})


//确认移除
const delRfid = (index)=>{
  // ElMessageBox.confirm("是否确认移除该标签", "提示", {
  //       confirmButtonText: "确认",
  //       cancelButtonText: "取消",
  //      customClass: "zh-message-box",
  //     }).then(() => {
  //       addList.value.splice(index,1)
  //   });
      addList.value.splice(index,1)
}
//设定时间
const setTime = ()=>{
   let e = formData.time
    if(!e) return
   let outTime;
   if(!goodsInfo.value.validityPeriodType || goodsInfo.value.validityPeriodType == 1){
        outTime = e
        return outTime
    }
  switch (goodsInfo.value.qualityGuaranteePeriodType) {
      case 3:
      outTime = moment(e).add(goodsInfo.value.qualityGuaranteePeriodNum,'y')
        break;
      case 2:
      outTime = moment(e).add(goodsInfo.value.qualityGuaranteePeriodNum,'M')
        break;
      case 1:
      outTime = moment(e).add(goodsInfo.value.qualityGuaranteePeriodNum,'d')
        break;
      default:
        break;
    }
  return outTime
}
//修改时间
const changeTotalTime = ()=>{
  let e = formData.time
  if(!e) return
  if(beginTimeType.value == 3){
    //我不清楚
    if(moment(e).isBefore(new Date())){
     goodsInfo.value.validityPeriodType = 2
    }else{
      goodsInfo.value.validityPeriodType = 1
    }
  }
  addList.value.forEach(item=>{
    if(item.labelStatus == 0){
      if(goodsInfo.value.validityPeriodType == 1){
        item.outTime = e
      }else{
        switch (goodsInfo.value.qualityGuaranteePeriodType) {
          case 3:
          item.outTime = moment(e).add(goodsInfo.value.qualityGuaranteePeriodNum,'y')
            break;
          case 2:
          item.outTime = moment(e).add(goodsInfo.value.qualityGuaranteePeriodNum,'M')
            break;
          case 1:
          item.outTime = moment(e).add(goodsInfo.value.qualityGuaranteePeriodNum,'d')
            break;
          default:
            break;
        }
      }
    }
  })
}

//查询rfid
const searchRfid = (e)=>{
  let index =addList.value.findIndex(item=>item.rfid.toUpperCase() == e.toUpperCase())
  if(index != -1) return
  labelBindInfo({
    rfid:e,
    orderSn:goodsInfo.value.orderSn,
    productId:goodsInfo.value.productId,
    modelId:goodsInfo.value.modelId,
  }).then((res)=>{
    if(res.code != 0){
      proxy.$message.error(res.msg)
      return
    }
     if(res.data.labelStatus == 0){
      res.data.outTime = setTime()
    }
    addList.value.push(res.data)
  })
}

const showError = (row)=>{
    if(row.labelStatus == 2){
      rfidChooseInfo.value = row
      rfidChooseInfo.value.title = `${row.serial} ，已绑定其他商品`
      showDia.value = true
    }
    if(row.labelStatus == 1){
      rfidChooseInfo.value = row
      rfidChooseInfo.value.title = `${row.serial}，不能重复绑定`
      showDia.value = true
    }
}
// useKeydown(searchRfid,24)

//解除绑定
const unBind = (e,type = 1)=>{
  ElMessageBox.confirm("是否确认解绑", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
       customClass: "zh-message-box",
      }).then(() => {
        labelUnbind({
          orderSn:type == 1?e.orderSn:goodsInfo.value.orderSn,
          productId:type == 1?e.productId:goodsInfo.value.productId,
          modelId:type == 1?e.modelId:goodsInfo.value.modelId,
          labelId:e.labelId,
        }).then((res)=>{
          if(res.code != 0){
            proxy.$message.error(res.msg)
            return
          }
          proxy.$message.success('解绑成功')
          if(type != 1){
             getRfidList()
          }else{
            let index = addList.value.findIndex(item=>item.labelId == e.labelId)
            addList.value.splice(index,1)
            searchRfid(e.rfid)
            showDia.value = false
          }
      })
    });
}
//确认绑定
const handleBind = ()=>{
  let list = []
  addList.value.forEach(item=>{
    if(item.labelStatus == 0){
      goodsInfo.value.followTime ? item.outTime = moment(item.outTime).valueOf() : item.outTime = 0
      list.push(item)
    }
  })
  let index = addList.value.findIndex(item=>item.labelStatus != 0)
  if(index != -1){
    proxy.$message.error(`标签存在已绑定情况，需要移除后才能继续绑定`)
    return
  }
  labelBind({
    orderSn:goodsInfo.value.orderSn,
    productId:goodsInfo.value.productId,
    modelId:goodsInfo.value.modelId,
    validityPeriodType:goodsInfo.value.validityPeriodType,
    qualityGuaranteePeriodNum:goodsInfo.value.qualityGuaranteePeriodNum,
    qualityGuaranteePeriodType:goodsInfo.value.qualityGuaranteePeriodType,
    labelList:list
  }).then((res)=>{
    if(res.code != 0){
      proxy.$message.error(res.msg)
      return
    }
    proxy.$message.success('标签信息绑定成功')
    addList.value = []
    getRfidList();
  })
}
function closeAdd (){
    proxy.$emit('close')
    tableData.value = [];
    addList.value = [];
    formData.time = '';
    for (const key in goodsInfo.value) {
      goodsInfo.value[key] = ''
      if (Object.hasOwnProperty.call(goodsInfo.value, key)) {
         goodsInfo.value[key] = ''
      }
    }
    //去除监听
    removeEventListener('keydown',key)
}
function key (e){
 keydown(e,searchRfid,24)
}
//弹窗打开产品列表接口
const openDia = () => {
  getRfidList();
  addEventListener('keydown',key)
  if(goodsInfo.value.qualityGuaranteePeriodTime && goodsInfo.value.validityPeriodType != 3){
    formData.time = getTime(goodsInfo.value.qualityGuaranteePeriodTime,goodsInfo.value)
  }
  if(goodsInfo.value.validityPeriodType){
    beginTimeType.value = goodsInfo.value.validityPeriodType
  }
};
function getTime (time,goodsInfo){
  if(!time) return ''
  if(goodsInfo.validityPeriodType == 1){
    //关注过期时间
    return moment(time).format('YYYY-MM-DD')
  }else{
    let newtime = ''
    let number = Number(goodsInfo.qualityGuaranteePeriodNum)
    //关注生产日期
    switch (goodsInfo.qualityGuaranteePeriodType) {
        case 3:
        newtime = moment(time).subtract(number,'y')
          break;
        case 2:
        newtime = moment(time).subtract(number,'M')
          break;
        case 1:
        newtime = moment(time).subtract(number,'d')
          break;
        default:
          break;
      }
      return moment(newtime).format('YYYY-MM-DD')
  }
}
const isOk = computed(()=>{
 if(addList.value.length == 0) return true
  let index2 = addList.value.findIndex(item=> !item.outTime )
  if(index2 != -1 && goodsInfo.value.followTime) return true
  return false
})
</script>
<style lang="scss" scoped>
.rfidTags {
  font-size: 12px;
  height: 20px;
  line-height: 20px;
  border-radius: 4px;
  padding: 0 6px;
  font-size: 12px;
  &.in {
    color: #57b0ff;
    background-color: rgba(87, 176, 255, 0.1);
    font-weight: normal;
  }
  &.out{
    color: #737480;
    background-color: rgba(115, 116, 128,0.1);
    font-weight: normal;
  }
  &.about {
    color: #3859ff;
    background-color: rgb(56, 89, 255, 0.1);
    font-weight: normal;
  }
  &.no {
    color: #737480;
    background-color: rgba(115, 116, 128, 0.1);
    font-weight: normal;
  }
  &.success {
    color: #48cb9f;
    background-color: rgba(72, 203, 159, 0.1);
    font-weight: normal;
  }
  &.isOther {
    color: rgb(255, 102, 105);
    background-color: rgba(255, 102, 105,0.1);
    font-weight: normal;
  }
  &.isIn {
    color: rgb(255, 145, 10);
    background-color: rgb(255, 145, 10,0.1);
    font-weight: normal;
  }
}
.goodInfo {
  font-weight: normal;
  .zh_content {
    height: 824px;
    box-sizing: border-box;
    position: relative;
    padding: 0 16px;

    .zh_introduce {
      margin-top: 0;
      padding: 16px;
      height: auto;
      display: block;
      .singles {
        margin-top: 16px;
      }
      .single {
        display: flex;
        align-items: center;
        .item {
          width: calc(100%/6)
        }
      }
    }
    .tabList {
      height: 700px;
      padding-top: 20px;
      padding-bottom: 16px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      .table_title {
        font-size: 16px;
        color: #161829;
        display: flex;
        height: 32px;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        .text {
          font-size: 12px;
          color: #aaaab2;
        }
      }
      .contBtn {
        font-size: 12px;
        color: $fsColor3;
        display: inline-block;
        cursor: pointer;
      }
      .isDoneList {
        box-sizing: border-box;
        width: 520px;
        padding: 16px;
        border-radius: 16px;
        background-color: #f9fbfb;
        overflow: hidden;
      }
      .willDoneList {
        width: 800px;
        padding: 16px;
        border-radius: 16px;
        background-color: #f9fbfb;
        overflow: hidden;
      }
    }
    .btnBox {
      position: absolute;
      bottom: 16px;
      right: 16px;
    }
  }
}

:deep(.el-table__header) {
  thead {
    th {
      background-color: #f9fbfb !important;
    }
  }
}

:deep(.el-table__body) {
  tr {
    td {
      background-color: #f9fbfb;
    }
  }
  tr:hover > td {
    background-color: rgba(56, 89, 255, 0.1);
  }
}
</style>
<style lang="scss" scoped>
:deep(.el-table) {
  background-color: #f9fbfb !important;
  border-bottom: 0;
  .in-row {
    td {
      background: rgba(255, 145, 10,0.1);
      cursor: pointer;
    }
  }
  .other-row {
    td {
      background: rgba(255, 102, 105,0.1);
      cursor: pointer;
    }
  }
  .instock-row {
    td {
      background: rgb(87, 176, 255,0.1)
    }
  }
  .event-row {
    td {
      background: #FAFBFC;
    }
  }
  .raido-row {
    td {
      background: #fff;
    }
  }
  .timeChooseBox {
    display: flex;
    align-items: center;
    justify-content: center;
    .w128 {
      width: 128px;
      margin-right: 14px;
    }
    .text {
      margin-right: 8px;
      display: inline-block;
    }
    .timeChoose {
      // width: 72px;
      border-radius: 8px;
      border: 1px solid #dcdee0;
      .el-input__wrapper {
        border: none !important;
        box-shadow: none !important;
        padding-right: 0;
      }
      .el-input__inner {
        border: none !important;
        box-shadow: none !important;
      }

      .el-select {
        --el-select-input-focus-border-color: transparent;
        .el-input__wrapper {
          padding-right: 4px;
          .el-input__suffix-inner > :first-child {
            margin-left: 2px;
          }
          .el-input__inner {
            flex-grow: 0;
            width: 14px;
          }
        }
      }
      .nbd {
        input {
          border: none !important;
        }
      }
      .timeChooseInput {
        width: 30px;
        position: relative;
        .el-input__inner {
          flex-grow: 0;
          position: relative;
          top: 1px;
        }
      }
      .timeChooseInput2 {
        width: 50px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.rfidInfo {
  .content {
    font-weight: normal;
    padding: 0 8px;
    .btnBox {
      margin-top: 24px;
      justify-content: center !important;
    }
    .item {
      font-size: 14px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      word-break: break-all;
      margin-bottom: 16px;
      .name {
        color: #737480;
      }
      .desc {
        @include textOverflow(200px);
        line-height: 1.3;
        color: #161829;
      }
    }
  }
}
.zh_introduce{
  .single{
    .item{
      .desc{
      }
    }
  }
}
</style>
