<template>
  <!-- 产品详情弹窗 -->
  <el-dialog
    :modelValue="modelValue"
    title="非本企业订单"
    width="1400"
    class="goodInfo"
    :close-on-click-modal="false"
    destroy-on-close
    align-center
    @open="openDetail"
    @close="close"
  >
    <div class="zh_content">
      <div class="info">
        <div class="dp_f">
          <div class="zh_title">基本信息</div>
        </div>
        <div class="zh_introduce">
          <div class="item">
            <div class="text">订单编号：</div>
            <div class="desc">{{ orderInfo.orderSn }}</div>
          </div>
          <div class="item">
            <div class="text">{{addSearchText}}：</div>
            <div class="desc code_btn cur_p" @click='changeThird'>{{ formData.thirdId ? formData.thirdName : `请选择${addSearchText}` }}</div>
            <div class="form mt_16" style="display: none;">
          <SelectByModalNew ref="inputRef" add @onChange="change" :type="searchModal.typeName" :queryText="formData.thirdName"  v-model="formData.thirdId"
                              class="w280"></SelectByModalNew>
        </div>
          </div>
          <div class="item">
            <div class="text">创建时间：</div>
            <div class="desc">{{$filters.timeFormat(orderInfo?.census?.createTime, 1)  }}</div>
          </div>
        </div>

        <div class="zh_title mt32">产品信息</div>
        <div class="zh_introduce">
          <div class="item">
            <div class="text">产品数量：</div>
            <div class="desc">{{orderInfo?.census?.count }}</div>
          </div>
          <div class="item">
            <div class="text">产品金额(元)：</div>
            <div class="desc">￥{{ $filters.toYuan(orderInfo?.census?.total, '元') }}</div>
          </div>
        </div>
        <div class="tabList" v-if="orderInfo?.list">
          <el-table :data="orderInfo?.list" class="zh-no-table-border"
                    :row-class-name="tableRowClassNameRelieve"
                    :cell-style="{border:'none'}"
                    :header-row-style="{border:'none'}" style="width: 100%" height="96%">
                    <el-table-column
                type="index"
                width="70"
                label="序号"
                align="center"
                fixed
            />
            <el-table-column
                width="200"
                align="center"
                class-name="table_text2"
                show-overflow-tooltip
            >
              <template #header>
                <div>产品名称</div>
              </template>
              <template #default="scope">
                <div> {{scope.row.equalGoods? scope.row.equalGoods.productName : scope.row.productName }}</div>
                <div class="table_text mt_16" v-if="scope.row.equalGoods">
                 <span class="sub_text">已对应：</span> {{scope.row.productName}}</div>
              </template>
            </el-table-column>
            <el-table-column
                width="130"
                align="left"
                class-name="table_text"
                label="产品品牌"
                show-overflow-tooltip
            >
              <template #default="scope">
                <div> {{scope.row.equalGoods? scope.row.equalGoods.brandName : scope.row.brandName }}</div>
                <div class="table_text mt_16" v-if="scope.row.equalGoods">{{scope.row.brandName}}</div>
              </template>
            </el-table-column>
            <el-table-column
                width="150"
                align="left"
                class-name="table_text2"
                show-overflow-tooltip
                label="产品单位"
            >
              <template #default="scope">
                <div
                    v-html="scope.row.equalGoods? getUnitName(scope.row.equalGoods) : getUnitName(scope.row) "></div>
                    <div class="table_text mt_16" v-if="scope.row.equalGoods" v-html="getUnitName(scope.row)"></div>
              </template>
            </el-table-column>
            <el-table-column
                width="150"
                align="left"
                class-name="table_text2"
                label="规格/型号"
                show-overflow-tooltip
            >
              <template #default="scope">
                <div>{{scope.row.equalGoods? scope.row.equalGoods.modelName : scope.row.modelName }}</div>
                <div class="table_text mt_16" v-if="scope.row.equalGoods">{{scope.row.modelName}}</div>
              </template>
            </el-table-column>
            <el-table-column width="150" align="left">
              <template #header>
                <div>数量</div>
              </template>
              <template #default="scope">
                <div class="table_text2">
                  {{ scope.row.count }}
                </div>
              </template>
            </el-table-column>
            <el-table-column width="100" align="left">
              <template #header>
                <div>单价(元)</div>
              </template>
              <template #default="scope">
                <div class="table_text2">
                  {{ scope.row.price }}
                </div>
              </template>
            </el-table-column>
            <el-table-column width="200" align="left">
              <template #header>
                <div>小计(元)</div>
              </template>
              <template #default="scope">
                <div class="table_text2">
                  {{ scope.row.totalPrice }}
                </div>
              </template>
            </el-table-column>
            <el-table-column  align="left">
              <template #header>
                <div>操作</div>
              </template>
              <template #default="scope">
                <span
                    class="contBtn"
                    v-if="scope.row.equalGoods"
                    @click="editProduct(scope.row)"
                    >修改</span
                  >
                  <span
                    class="contBtn"
                    v-else
                    @click="editProduct(scope.row)"
                    >对应产品</span
                  >
                  <span
                    class="contBtn ml_8"
                    @click="delPro(scope.row)"
                    >移除</span
                  >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="remark">
        <div class="btnBox ">
          <div class="gyText"><span class="red">*</span>请选择仓库</div>
          <el-select
              v-model="formData.stockId"
              placeholder="请选择"
              class="w200 ml_16"
              clearable
          >
            <template  v-for="item in stockList" :key="item.stockId">
            <el-option v-if="item.stockId" :label="item.stockName" :value="item.stockId"/>
            </template>
          </el-select>
          <el-button class="ml_16" :disabled="!canCreateOrder" @click="handleSubmit" type="primary" >{{ showRemainCountText }}</el-button>
        </div>
        </div>
      </div>
    </div>
  </el-dialog>
  <RelevanceModal v-model="showModal" :productInfo="productInfo" @confirm="changeProductInfo"></RelevanceModal>
</template>
<script setup>
import RelevanceModal from './relevanceModal.vue'
import {getConfig} from "../../uitl";
import {orderAdd} from "@/utils/api/order.js"
import {tableRowClassNameRelieve} from "@/utils/tool.js";
import {computed, inject, reactive, ref, toRefs, onMounted} from "vue";
import {getUnitName} from "@utils/tool/orderTools";
import useStock from '@/utils/hooks/useStock';
import message from '@/utils/tool/message';
import useModal from '@/utils/hooks/useModal';
import SelectByModalNew from "@components/form/selectByModalNew/index.vue";
const inputRef = ref(null)
const orderInfo = inject('orderInfo')
const {stockList ,getStockListData} = useStock()
const {showModal,handleCloseModal,handleOpenModal } =useModal()
const productInfo = ref({})
const changeProductInfo = (val) => {
  if(!val?.equalGoods){
    //解除对应
    const index = orderInfo.value.list.findIndex((item) => item?.equalGoods?.goodsId === val.goodsId)
    if(index !== -1){
      orderInfo.value.list[index].equalGoods = null
    }
  }else{
    const index = orderInfo.value.list.findIndex((item) => item.goodsId === val.goodsId)
    orderInfo.value.list[index] = val
    productInfo.value = val
  }
}
const props = defineProps({
  modelValue:{
    type: Boolean,
    default: false
  },
  orderType:{
    type: [String, Number],
    default: ''
  }
});
const { searchModal, addSearchText } = getConfig(props.orderType)
const baeeForom = reactive({
  stockId: '',
  thirdId: '',
  thirdName: ''
})
const formData = reactive({
  ...baeeForom
})

const emit = defineEmits(['update:modelValue','closeModal']);
const openDetail = () => {
  Object.assign(formData,baeeForom)
  const companyName = orderInfo.value?.census?.companyName || ''
  formData.thirdName = companyName || ''
};
const changeThird = ()=>{
  inputRef.value.handleShowCheckModal()
}
const change = (val)=>{
  formData.thirdName = val?.name || ''
}
const close = () => {
  emit("update:modelValue", false);
  emit("closeModal");
}
//编辑对应商品
const editProduct = (row) => {
  productInfo.value = {...row}
  handleOpenModal()
}

//移除产品
const delPro = (row) => {
  const index = orderInfo.value.list.findIndex((item) => item.goodsId === row.goodsId)
  orderInfo.value.list.splice(index, 1)
}

onMounted(()=>{
  getStockListData()
})
const showRemainCount = computed(() => {
  let count = 0
  if (orderInfo.value.list) {
    count = orderInfo.value.list.filter((item) => !item.equalGoods).length
  }
  return count
})

//是否可以点击创建订单
const canCreateOrder = computed(() => {
  return formData.thirdId !== '' && formData.stockId!== '' && showRemainCount.value === 0 && orderInfo.value.list.length > 0
})
//展示剩余数量
const showRemainCountText = computed(() => {
  const text = `生成${+orderInfo.value.orderType === 1 ? '采购' : '销售'}订单`
  if(showRemainCount.value === 0){
    return text
  }
  return `${text}（剩余${showRemainCount.value}个产品未对应）`
})
const handleSubmit = async ()=>{
 const list = orderInfo.value.list.map(item=>{
  return Object.assign({},item,{...item.equalGoods},{
    price:item.price,
    totalPrice:item.totalPrice,
    count:item.count,
  })
 })
 const params = {
  thirdId: formData.thirdId,
  thirdName: formData.thirdName,
  stockId: formData.stockId,
  remark: '',
  list: list,
  orderType:props.orderType,
  createType:2
 }
 const {data,msg,code} = await orderAdd(params)
 if(code !== 0){
  message.error(msg)
  return
 }
 message.success('订单生成成功')
 close()
}
</script>

<script>
import RfidList from "@/components/rfidList/index.vue";
import AddRfidList from "@/components/rfidList/addRfidList.vue";
import { reactive, toRefs, ref, watch, getCurrentInstance } from "vue";
import { reactiveAssign } from "@/utils/tool.js";
import { ArrowDown } from "@element-plus/icons-vue";
import SelectGoodsFromList from "@/components/selectGoodsFromList/index.vue";
import SelectByAbcModal from "@/components/selectByAbcModal/index.vue";
export default {
  name: "oredrInfo",
  components: {
    ArrowDown,
    RfidList,
    AddRfidList,
    SelectByAbcModal,
    SelectGoodsFromList,
  },
};
</script>
<style lang="scss" scoped>
.goodInfo {
  .qrCode{
          width: 60px;
          height: 60px;
        }
  .zh_content {
    padding:0 16px 0;
    height: 824px;
    box-sizing: border-box;
    position: relative;
    .zh_introduce {
      .code_btn{
          color: $mainColor;
          text-decoration: underline;
        }
      &.status3 {
        flex-wrap: wrap;
        height: 80px;
        padding: 15px;
        box-sizing: border-box;

        .item {
          width: 33%;
          @include textOverflow(33%);
          margin-bottom: 15px;
        }
      }
    }
    .tabList {
      height: 590px;
      overflow-y: auto;
      @include overFlow();
      padding-top: 20px;
      padding-bottom: 16px;
      box-sizing: border-box;
      .name {
        color: #73737f;
      }
      .text2 {
        color: $fsColor;
      }
      .contBtn {
        font-size: 12px;
        color: $fsColor3;
        display: inline-block;
        cursor: pointer;
        &.top1 {
          margin-left: 6px;
          position: relative;
          top: -1px;
        }
      }
      .el-input__inner {
        background-color: #f9fbfb !important;
      }
    }
    .remark {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 178px;
      padding: 0 32px 12px;
      box-sizing: border-box;
      .btnBox {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: absolute;
        right: 32px;
        bottom: 12px;
        .info_btn {
          width: 112px;
        }
        .select {
          margin: 0 8px;
          width: 160px;
          height: 40px;
          :deep(.el-input) {
            height: 40px;
            line-height: 40px;
          }
        }
      }
    }
  }
}

:deep( .el-textarea__inner) {
  height: 76px;
  border-radius: 8px;
  background-color: #f9fbfb;
}
</style>
