<template>
  <div class="addOrder">
    <div class="content">
      <div class="queryBoxOne">
        <div class="btnBox">
          <div class="gyText"><span class="red">*</span>{{addSearchText}}</div>
          <SelectByAbcModal
              :typeName="searchModal.typeName"
              :maxNum="1"
              v-model:list="formData.searchList"
              className="w240"
              :placeholder="searchModal.placeholder"
              :saleBack="orderType == 3 "
              :buyBack="orderType == 10 "
              @change='changeSearchList'
          ></SelectByAbcModal>
          <div class="gyText ml_16" v-show='isShowStock'><span class="red">*</span>{{ addStockText }}</div>
          <el-select
              v-show='isShowStock'
              v-model="formData.stockId"
              placeholder="请选择"
              class="w240"
              clearable
              @change='changeSearchList'
          >
            <el-option v-for="item in sotckList" :key="item.stockId" :label="item.stockName" :value="item.stockId"/>
          </el-select>
        </div>
        <div class="queryItem">
          <div class="queryBtn animate__animated animate__zoomIn" v-show='isShowGoods'>
            <el-button @click="openAdd" type="primary" >批量添加产品</el-button>
          </div>
        </div>
      </div>
      <div class="census animate__animated animate__zoomIn" v-show='isShowGoods'>
        <div class="item">
          <span class="text">计划数量：</span>
          <span class="num">{{ getSum.total }}</span>
        </div>
        <div class="item">
          <span class="text">合计金额(元)：</span>
          <span class="num">￥{{ getSum.sum }}</span>
        </div>
      </div>
      <div class="tabList" v-show='isShowGoods'>
        <el-table :data="tableData" style="width: 100%" height="100%">
          <el-table-column label="序号" width="100" align="center" fixed>
            <template #default="scope">
              <div class="table_text">
                {{
                  (pageInfo.pageNumber - 1) * pageInfo.pageSize +
                  scope.$index +
                  1
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="254" align="center" fixed>
            <template #header>
              <div><span class="red">*</span> 产品名称</div>
            </template>
            <template #default="scope">
              <div class="table_text2 tableName160" v-if="scope.row.productId">
                {{ scope.row.productName }}
              </div>
              <SelectGoodsFromList
                  :propList='tableData'
                  v-model:goods="tableData[scope.$index]"
                  @change="pushGoods"
                  :thirdId='formData.thirdId'
                  :stockId='formData.stockId'
                  v-else
              ></SelectGoodsFromList>
            </template>
          </el-table-column>
          <el-table-column width="120" align="center" label="简称" show-overflow-tooltip>
            <template #default="scope">{{ scope.row.subName }}
            </template>
          </el-table-column>
          <el-table-column
              width="120"
              align="center"
              label="规格/型号"
              show-overflow-tooltip
              class-name="table_text2"
          >
            <template #default="scope">{{ scope.row.modelName }}
            </template>
          </el-table-column>
          <el-table-column width="100" align="center" label="产品单位" show-overflow-tooltip>
            <template #default="scope">
              <div
                  class="table_text2"
                  v-html="getUnitName(scope.row)"></div>
            </template>
          </el-table-column>
          <el-table-column width="160" align="center" label="产品品牌" show-overflow-tooltip>
            <template #default="scope">
              <span v-if="scope.row.productId">
                {{ scope.row.brandName || '--' }}
              </span>
            </template>
          </el-table-column>
          <el-table-column width="100" v-if='showLabelCount()' align="center" class-name="table_text2" label="可用库存" show-overflow-tooltip>
            <template #default="scope">{{ getStockCount(scope.row) }}
            </template>
          </el-table-column>
          <el-table-column width="160" align="center">
            <template #header>
              <div><span class="red">*</span> 计划数量</div>
            </template>
            <template #default="scope">
              <el-input
                  v-elInput
                  v-number
                  maxlength="10"
                  class="name tablInput w96"
                  placeholder="计划数量"
                  v-model="scope.row.count"
                  @blur="($event)=>{setNum($event,scope.$index)}"
                  v-if="scope.row.productId && !scope.row.scattered"
              />
              <el-input
                  v-elInput
                  v-number.fixed
                  maxlength="10"
                  class="name tablInput w96"
                  placeholder="计划数量"
                  v-model="scope.row.count"
                  @blur="($event)=>{setNum($event,scope.$index)}"
                  v-if="scope.row.productId && scope.row.scattered"
              />
            </template>
          </el-table-column>
          <el-table-column width="160" align="center" >
            <template #header>
              <div><span class="red">*</span>单价(元)</div>
            </template>
            <template #default="scope">
              <el-input
                  v-elInput
                  v-number.fixed
                  class="name tablInput w96"
                  v-model="scope.row.price"
                  placeholder="输入单价"
                  maxlength="10"
                  @blur="($event)=>{setPrice($event,scope.$index)}"
                  v-if="scope.row.productId"
              />
            </template>
          </el-table-column>
          <el-table-column width="160" align="center" label="小计(元)" >
            <template #default="scope">
              <el-input
                  v-elInput
                  v-number.fixed
                  class="name tablInput w96"
                  v-model="scope.row.totalPrice"
                  placeholder="小计"
                  maxlength="20"
                  @blur="($event)=>{setTotalPrice($event,scope.$index)}"
                  v-if="scope.row.productId"
              />
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template #default="scope">
              <div class="contBtn" @click='delGoods(scope.$index)' v-if="scope.$index != tableData.length - 1">
                移除
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="remark">
        <div class="title">备注</div>
        <div class="input">
          <el-input
              v-elInput
              v-model="remark"
              type="textarea"
              placeholder="输入信息"
              class="textareaInput"
              input-style="border: none;box-shadow:none"
              resize="none"
              maxlength="100"
          />
        </div>
        <div class="btnBox">
          <el-button class="btn1 zh-btn w160" @click="cancel">取消</el-button>
          <el-button class="main btn1 w160" type="primary" :disabled="!canConfirm" @click="confirm">提交</el-button>
        </div>
      </div>
    </div>
    <Bind v-model="bindDia"   title='批量添加' :thirdId='formData.thirdId' :stockId='formData.stockId' :propList='tableData'  @close='closeAdd' @save='moreAddOk'></Bind>
  </div>
</template>
<script setup>
import Bind from './component/bind.vue';
import {ElMessageBox} from "element-plus";
import {computed, getCurrentInstance, onMounted, reactive, ref} from "vue";
import {stockList} from "@/utils/api/procurement/stock.js";
import {orderAdd} from "@/utils/api/order.js"
import {cloneDeep} from 'lodash';
import {useRoute, useRouter} from "vue-router";
import {getConfig} from '../uitl'
import {getStockCount, getUnitName} from "@/utils/tool/orderTools"

const router = useRouter();
const route = useRoute();
const orderType = route.params.orderType || 0;
const { proxy } = getCurrentInstance();


const formData = reactive({
  searchList: [],
  thirdName: "",
  thirdId: "",
  stockId: "",
});

const { searchModal,addSearchText,addStockText } =getConfig(orderType)

const remark = ref('')
const bindDia = ref(false)

//打开批量添加弹窗
const openAdd = () => {
  bindDia.value = true
}

//保存批量添加
const moreAddOk = (e) => {
  tableData.value.pop()
  tableData.value = tableData.value.concat(e)
  tableData.value.push({})
}

const closeAdd =()=>{
  bindDia.value = false
}

const pushGoods = (e) => {
  let index = tableData.value.findIndex((item) => item.productId === e.productId);
  if (index !== -1) {
    e.brandName = tableData.value[index].brandName
    e.brand = tableData.value[index].brand
    e.brandId = tableData.value[index].brandId
  }
  tableData.value.push({});
};
const tableData = ref([{}]);
const pageInfo = reactive({
  pageNumber: 1,
  pageSize: 10,
});
const sotckList = ref([])
//获取仓库列表
const getSotckList = () => {
  stockList({
    status:true,
    all:false
  }).then((res) => {
    if (res.code !== 0) {
      proxy.$message.error(res.msg);
      return;
    }
    sotckList.value = res.data.list || [];
  });
};
//计算小计
const getSubtotal = (e) => {
  return "￥" + (Number(e.price) * Number(e.count) || 0).toFixed(2);
};
//机选总金额
const getSum = computed(() => {
  let sum = 0;
  let total = 0;
  tableData.value.forEach((item) => {
    if(item.productId){
      sum +=  +item.totalPrice || 0 ;
      total += +item.count || 0;
    }
  });
  return {
    sum,
    total,
  };
});

//设置单价
const setPrice = (e,index) => {
  tableData.value[index].price = e.target.value || 0;
  if (!tableData.value[index].count || tableData.value[index].count === 0 || tableData.value[index].count === '0.00') {
    return
  }
  tableData.value[index].totalPrice = (
      Number(tableData.value[index].price) * Number(tableData.value[index].count)
  ).toFixed(2);
};

//通过数量设置总价
const setNum = (e, index) => {
  tableData.value[index].count = e.target.value || 0;
  if (!tableData.value[index].count || tableData.value[index].count === 0 || tableData.value[index].count === '0.00') {
    return
  }
  console.log( Number(tableData.value[index].price) * Number(tableData.value[index].count))
  if(tableData.value[index].price !== 0 && tableData.value[index].price !== '0.00'){
    tableData.value[index].totalPrice = (
        Number(tableData.value[index].price) * Number(tableData.value[index].count)
    ).toFixed(2);
    return
  }
  if(tableData.value[index].totalPrice !== 0 && tableData.value[index].totalPrice !== '0.00'){
    tableData.value[index].price = (
        Number(tableData.value[index].totalPrice) / Number(tableData.value[index].count)
    ).toFixed(2);
  }
}

//设置总价
const setTotalPrice = (e,index) => {
  tableData.value[index].totalPrice = e.target.value || 0;
  if (!tableData.value[index].count || tableData.value[index].count === 0) {
    return
  }
  tableData.value[index].price = (
      Number(tableData.value[index].totalPrice) / Number(tableData.value[index].count)
  ).toFixed(2);
};

//清空数据
const clearData = () => {
  tableData.value = [{}];
  formData.punter = [];
  formData.punterName = "";
  formData.punterId = "";
  remark.value = ''
};

//移除
const delGoods = (index) => {
  tableData.value.splice(index, 1);
};

const showLabelCount = ()=>{
  let list = ["6","7","8","9","10","11","12",]
  return list.includes(orderType)
}

//取消
const cancel = () => {
  ElMessageBox.confirm("信息未保存，是否确认取消！", "提示", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    customClass: "zh-message-box",
  }).then(() => {
    proxy.$emit('close')
  })
};

//确认
const confirm = () => {
  let list =cloneDeep(tableData.value)
  list.pop()
  // list.forEach((item)=>{
  //   item.price = (parseFloat(item.price) * 1000)
  //   item.totalPrice = (parseFloat(item.totalPrice) * 1000)
  // })
  orderAdd({
    thirdId: formData.thirdId,
    thirdName: formData.thirdName,
    stockId: formData.stockId,
    remark: remark.value,
    list: list,
    orderType:route.params.orderType || undefined
  }).then((res) => {
    if (res.code === 0) {
      proxy.$message.success("订单添加成功")
      proxy.$emit('close')
    }else{
      proxy.$message.error(res.msg)
    }
  });
};

//确认是否可提交
const canConfirm = computed(()=>{
  return tableData.value.every((item,index)=>{
    //判断item.count非空不等于0
    if(index === tableData.value.length - 1) return true
    return item.productId && (item.count && item.count != 0)
  }) && formData.searchList && tableData.value.length > 1 && formData.searchList?.length > 0 && formData.stockId
})


//改变供应商或者客户
const changeSearchList = () => {
  tableData.value = [{}]
  formData.thirdId = formData.searchList[0]?.punterId || formData.searchList[0]?.supplierId || ''
  formData.thirdName = formData.searchList[0]?.punterName || formData.searchList[0]?.supplierName || ''
  // if( formData.searchList?.length > 0 && (orderType == 1 || orderType == 10)){
  //   //供应商 ,采购入库订单
  //   formData.thirdId = formData.searchList[0]?.supplierId || ''
  //   formData.thirdName = formData.searchList[0]?.supplierName || ''
  // }
  // if( formData.searchList?.length > 0 && (orderType == 3 || orderType == 7)){
  //   //客户 ,销退入库 ,销售出库
  //   formData.thirdId = formData.searchList[0]?.punterId || ''
  //   formData.thirdName = formData.searchList[0]?.punterName || ''
  // }
}

//是否显示商品或者批量添加
const isShowGoods = computed(()=>{
  return formData.searchList?.length > 0 && formData.stockId
})

//是否展示仓库选择
const isShowStock = computed(()=>{
  switch (orderType) {
    case "1":
      return true
    case "3":
      return true
    case "7":
      return true
    default:
      return true
  }
})


onMounted(()=>{
  getSotckList()
})
</script>

<script>
import { ArrowDown, Search } from "@element-plus/icons-vue";
import SelectGoodsFromList from "@/components/selectGoodsFromList/index.vue";
import SelectByAbcModal from "@/components/selectByAbcModal/index.vue";
export default {
  name: "addOrder",
  components: {
    Search,
    ArrowDown,
    SelectGoodsFromList,
    SelectByAbcModal,
  },
};
</script>

<style lang="scss">
@import "./index.scss";
:deep(.el-table__row) {
  cursor: pointer;
}
</style>
