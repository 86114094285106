<template>
  <el-button @click="handleRelevanceOrder">关联订单</el-button>
  <orderDetail :order-type="props.orderType" @closeModal="closeDetail"  v-model="showOrderDetailModal"></orderDetail>
  <RelevanceOrderModal @confirm="confirm" :order-type="props.orderType" v-model="showModal"></RelevanceOrderModal>
</template>

<script setup>
import RelevanceOrderModal from "./relevanceOrderModal/index.vue";
import orderDetail from "./relevanceOrderModal/orderDetail.vue";
import useModal from "@utils/hooks/useModal";
import { ref,provide,inject } from "vue";
const { showModal, handleOpenModal,handleCloseModal } = useModal();
const { showModal:showOrderDetailModal,handleOpenModal: handleOpenOrderDetailModal,handleCloseModal: handleCloseOrderDetailModal } = useModal();

const props = defineProps({
  orderType:{
    type:[String,Number],
    default:''
  }
})
const emit = defineEmits(['closeModal'])
const closeDetail = ()=>{
  handleCloseOrderDetailModal()
  emit('closeModal')
}
const orderInfo = ref({});
provide("orderInfo",orderInfo)
//点击关联订单
const handleRelevanceOrder = () => {
  handleOpenModal();
}
const confirm = (order) => {
  handleCloseModal()
  orderInfo.value = {...order,orderType:props.orderType};
  handleOpenOrderDetailModal()
}
</script>

<style lang="scss" scoped>

</style>